<template>
  <div class="inline-loading row">
    <div ref="lottieLoadingRef" class="lottie-loading col-auto warning-icon"></div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import lottie from 'lottie-web';
import esliteLoadingJsonData from '@/static/lottie/eslite_loading.json';

const lottieLoadingRef = ref(null);
const anim = ref(null);
const lottieInit = () => {
  anim.value = lottie.loadAnimation({
    container: lottieLoadingRef.value,
    renderer: 'svg',
    loop: true,
    autoplay: true,
    name: 'esliteLoading',
    animationData: esliteLoadingJsonData,
  });
};

onMounted(() => {
  lottieInit();
});
</script>
<script>
export default {
  name: 'UtilityInlineLoadingBig',
};
</script>
<style lang="scss" scoped>
.inline-loading {
  width: 100%;
  height: 100%;
  min-height: 140px;
  text-align: center;
  margin: 0 auto;
  padding: 0;

  .center {
    margin: 0 auto;
  }
}

.warning-icon {
  width: 100px;
  padding-top: 4rem;
  margin: 0 auto 2rem;
}
</style>
