<template>
  <div class="banner-box tw-bg-white">
    <div class="banner-box-title-row full" :style="{ backgroundColor: useColor.color }">
      <div class="title">
        <img src="@/static/images/eslite-logo-icon-36px.svg" />
        <span>{{ name }}</span>
      </div>
    </div>
    <div class="banner-box-body">
      <div :id="`js-affix-link-${cid}`" class="thread-element" :style="`--thread-color: ${useColor.color}; --thread-hover: ${useColor.hover}; --thread-active: ${activityColor};`">
        <ul v-if="items.length > 1" class="tabs">
          <li
            v-for="(item, index) in formattedTabs"
            :key="index"
            :class="{ marketing: item.nameType === 'marketing', active: currentTab === item.index, empty: isEmptyValue(item.name) }"
            @click="changeTab(item.index)"
          >
            {{ item.name }}
          </li>
        </ul>
        <div class="body" :style="{ borderColor: useColor.color }">
          <RouterLinkUsage :link="banner.link" :title="banner.title" class="banner" target="_blank"><img :src="banner.image" :alt="banner.title" /></RouterLinkUsage>
          <div v-if="!$isEmpty(products)" ref="productsBlockElement" class="products-wrapper">
            <RowProducts :products="products" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, toRefs, computed, onMounted } from 'vue';
import { map } from 'ramda';
import { usePriceAndImageStore } from '@/stores/price-and-image';
import RowProducts from '@/components/layout/elements/mobile/row-products.vue';
import RouterLinkUsage from '@/components/utility/router-link-usage.vue';
import { categoryColors, activityColor } from '@/constant/ad/category-colors';
import { isEmptyValue } from '@/helper/data-process';

const props = defineProps({
  name: { type: String, default: '' },
  items: { type: Array, default: () => [] },
  cid: { type: Number, default: 0 },
});

const { name, items, cid } = toRefs(props);

const productsBlockElement = ref(null);
const currentTab = ref(0);
const priceStore = usePriceAndImageStore();
const { fetchPrice } = priceStore;

const currentData = computed(() => items.value[currentTab.value]);

const insertSpaces = (arr) => {
  return arr.length > 1
    ? arr.reduce((acc, item, index) => {
        if (index > 0) acc.push({ name: '', index: undefined, nameType: '' });
        acc.push({ name: item.name, index, nameType: item.nameType, link: item.link, sort: item.sort });
        return acc;
      }, [])
    : arr.map((item, index) => ({ name: item.name, index, nameType: item.nameType, link: item.link, sort: item.sort }));
};
const formattedTabs = computed(() => insertSpaces(items.value).slice(0, 11));

const banner = computed(() => currentData.value?.banner || {});
const products = computed(() => (currentData.value?.products || []).slice(0, 7));

const useColor = computed(() => categoryColors[cid.value] || categoryColors[0]);

const changeTab = (index) => {
  if (isEmptyValue(index)) return false;
  currentTab.value = index;

  const ids = map((x) => x?.esliteSN, products.value || []);
  if (ids.length > 0) fetchPrice({ productIds: ids });

  if (productsBlockElement?.value) productsBlockElement.value.scroll({ left: 0, behavior: 'smooth' });
  return true;
};

onMounted(() => {
  changeTab(0);
});
</script>

<style scoped>
.banner-box {
  width: 100%;
  padding: 0px;
  margin-bottom: 16px;
  border-bottom: 1px solid theme('colors.gray-100');
}

.banner-box-title-row {
  @apply tw-text-[20px] tw-leading-6 tw-font-bold;
  padding: 20px 16px 24px;
  color: theme('colors.white');

  &.full {
    padding: 0px;
  }

  .title {
    @apply tw-px-4 tw-py-5 tw-flex tw-items-center tw-flex-row;
  }

  img {
    @apply tw-w-8 tw-h-8 tw-opacity-40;
  }
  span {
    @apply tw-ml-2;
  }
}

.banner-box-body {
  padding: 20px 16px 24px;
  &.full {
    padding: 0px 0px 24px;
  }
}

.tabs {
  list-style: none;
  display: flex;
  padding: 0;
  margin-bottom: 20px;
  overflow: auto;
}
.tabs li {
  cursor: pointer;
  flex-shrink: 0;
  @apply tw-p-[8px] tw-text-[14px] tw-leading-[22px] tw-font-medium tw-rounded;
  color: theme('colors.gray-400');

  &.marketing {
    color: theme('colors.pink-500');
  }
  &.active {
    color: var(--thread-color);
    background-color: var(--thread-hover);
  }
  &.empty {
    cursor: default;
    color: theme('colors.gray-200');
    line-height: 18px;
    &::before {
      font-size: 10px;
      content: '';
      border-right: 1px solid theme('colors.gray-200');
    }
  }
}

.banner {
  @apply tw-mb-4 tw-block;
  img {
    @apply tw-w-full tw-rounded-lg;
  }
}

.products-wrapper {
  overflow: auto;
}
</style>
