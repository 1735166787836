import { ref, computed, inject, nextTick } from 'vue';
import { debounce } from 'lodash-es/function';
import { trim, includes, length, dropLast } from 'ramda';
import { useSearchLocalRecord } from '@/composables/search/local-record';
import { isEmptyValue } from '@/helper/data-process';
import { arrowKeyEnum, autoCompleteCount, specialKeyEnum, hoverDefaultIndex, keywordTypeEnum } from '@/constant/search/auto-complete';
import { useSearchAb } from '@/composables/search/search-ab';
import SearchRecommendFactory from '@/services/search/search-recommend-factory';
import { useLocalRecommendEvent } from '@/composables/search/local-recommend-event';
import { useRetailLogEvent } from '@/composables/search/retail-log-event';

function isArrowUpDown(event) {
  return includes(event.key, [arrowKeyEnum.up, arrowKeyEnum.down]);
}

export function useSearchAutoComplete({ searchFormRef, searchButtonRef, query, emit }) {
  const $screen = inject('$screen');

  const { setLocalRecord, getLocalRecord } = useSearchLocalRecord({ query });
  const { setLocalRecommendEventLog } = useLocalRecommendEvent();

  const isShowRecommends = ref(false);
  // search keywords
  const recommendItems = ref([]);
  const searchKeyword = ref('');
  // hover
  const hoverIndex = ref(hoverDefaultIndex);
  // const hoverItem = ref('');
  // keywordType
  const keywordType = ref(keywordTypeEnum.none);

  // 多個 request，清除前一個
  let abortController = null;

  /**
   * a/b
   */
  const { setSearchToken, searchToken } = useRetailLogEvent();
  const { apiVersion } = useSearchAb();

  const service = new SearchRecommendFactory(apiVersion);

  const items = computed({
    get() {
      // devices count limit
      const showLength = $screen.isMobileSize ? autoCompleteCount.mobile : length(recommendItems.value);
      return dropLast(length(recommendItems.value) - showLength)([...recommendItems.value]);
    },
    set(value) {
      recommendItems.value = [...value];
    },
  });
  const isShowRecommendList = computed(() => length(items.value) > 0);

  // 隱藏推薦詞
  const hideRecommends = () => {
    hoverIndex.value = hoverDefaultIndex;
    isShowRecommends.value = false;
  };
  // 顯示推薦詞
  const showRecommends = () => {
    hideRecommends();
    isShowRecommends.value = true;
  };
  // 選擇推薦詞
  const selectRecommendedWords = async (keyword) => {
    query.value = keyword;
    hoverIndex.value = hoverDefaultIndex;
    hideRecommends();
    // 必須等 query.value 抽換完畫面的值，才能 submit
    await nextTick();

    // 將 recommend 資料寫入 local storage FOR 搜尋頁 event api
    setLocalRecommendEventLog(query.value, searchToken.value, items.value);
    searchFormRef.value?.submit();
  };
  const setAbortController = () => {
    // remove prev request
    if (abortController) {
      abortController.abort();
    }
    // set abortController
    abortController = new AbortController();
  };
  const apiGetRecommendWords = (keyword) => {
    setAbortController();

    // call auto complete api
    return service
      .get(keyword, abortController)
      .then((recommends) => {
        if (isEmptyValue(query.value)) return; // 已切換回 history
        searchKeyword.value = recommends.keyword;
        keywordType.value = keywordTypeEnum.recommend;
        items.value = dropLast(length(recommends.list) - autoCompleteCount.recommends, recommends.list);
        // token for event
        setSearchToken(recommends?.token || '');
      })
      .catch((error) => {
        // 預期內的 cancel，不算在 error
        if (error?.message === 'cancel') return;
        console.log(error);
      });
  };
  // 取得本機(Local Storage)中的推薦詞
  const getLocalRecommends = () => {
    keywordType.value = keywordTypeEnum.history;
    items.value = getLocalRecord();
    searchKeyword.value = '';
  };
  const getRecommends = async (input) => {
    const words = trim(input);
    // 關鍵字空的跑 history
    if (isEmptyValue(words)) {
      getLocalRecommends();
      // 關鍵字有內容 -> 問 api
    } else {
      await apiGetRecommendWords(words);
    }
    showRecommends();
  };
  const debounceGetRecommends = debounce(async function (event) {
    await getRecommends(event.target.value);
  }, 100);
  const isInvalidHover = () => {
    return isEmptyValue(hoverIndex.value) || hoverIndex.value < 0 || hoverIndex.value >= length(items.value);
  };
  const normalKeyProcess = (event) => {
    // special keys
    if (event.key === specialKeyEnum.esc) return;
    if (isArrowUpDown(event)) return;

    // [mac] 注音輸入法輸入中會以底線的方式 ___  先把完成的字咬住，最後搭配 `enter` 才將完整的字帶入 `query.value(input)`，所以這邊 enter 不能完全濾掉。
    if (event.key === specialKeyEnum.enter && !isInvalidHover()) return;

    // clear before
    hideRecommends();

    // get recommends
    debounceGetRecommends(event);
  };
  const focusOut = () => {
    // 正在選擇時，不關閉建議詞 list
    if (hoverIndex.value !== hoverDefaultIndex) return;
    hideRecommends();
    emit('focusout');
  };
  const focusInput = async () => {
    emit('typing');
    await getRecommends(query.value);
  };
  const over = (word) => {
    hoverIndex.value = word.index;
    // hoverItem.value = word.text;
  };
  const keyDown = (event) => {
    if (event.key === arrowKeyEnum.down) hoverIndex.value += 1;
    if (event.key === arrowKeyEnum.up) hoverIndex.value -= 1;
  };
  const focusSearchButton = () => {
    searchButtonRef.value?.focus();
  };
  const escapeInput = () => {
    query.value = '';
    focusSearchButton();
    hideRecommends();
  };
  const confirmHover = (event) => {
    // 未 hover 或資料異常
    if (isInvalidHover()) return;
    // enter
    if (event.key !== specialKeyEnum.enter) return;
    query.value = items.value[hoverIndex.value];
    hideRecommends();
  };
  const keyUp = (event) => {
    if (event.key === specialKeyEnum.esc) {
      escapeInput();
      return;
    }

    normalKeyProcess(event);
    confirmHover(event);
  };
  const inputComplete = (input) => {
    emit('input', input);
    // 不為空時觸發
    if (!isEmptyValue(input)) keyUp({ key: 'system', target: { value: input } });
  };

  return {
    isShowRecommends,
    searchKeyword,
    hoverIndex,
    keywordType,
    items,
    isShowRecommendList,
    searchToken,
    selectRecommendedWords,
    isInvalidHover,
    focusOut,
    focusInput,
    over,
    keyDown,
    keyUp,
    inputComplete,
    setLocalRecord,
  };
}
