/**
 * 會員資料隱碼
 */

/**
 * 隱碼
 * @param {string} data 隱碼資料
 * @param {number} preDisplayIndex 起始位置
 * @param {number} maskLength 長度
 * @param {string} code 隱碼替換元素
 * @returns {string} 隱碼後資料
 */
const encrypt = (data, preDisplayIndex, maskLength, code = '*') =>
  Array.from(data)
    .map((num, key) => (key < preDisplayIndex || key > preDisplayIndex - 1 + maskLength ? num : code))
    .join('');

/**
 * 台灣身分證
 * @param {string} id
 */
const isTaiwanID = (id = '') => /^[A-Z][1,2,8,9]\d{8}$/.test(id);

/**
 * 帳號
 * @param {string} account account or email
 * @param {string} code 隱碼替換元素
 * @returns {string} 隱碼後資料
 */
export function encryptAccount(account = '', code = '*') {
  const isEmail = account.indexOf('@') > -1;
  const accountArr = account.split('@');
  const accountStr = isEmail ? accountArr[0] : account;
  const format = encrypt(accountStr, 0, 3, code);
  if (isEmail) accountArr[0] = format;

  return isEmail ? accountArr.join('@') : format;
}

/**
 * 姓名
 * @param {string} str
 * @param {string} code 隱碼替換元素
 * @returns {string} 隱碼後資料
 */
export const encryptName = (name = '', code = '*') => (/^[\u4e00-\u9fa5\u3400-\u4DBF]{0,}$/.test(name) ? encrypt(encrypt(name, 1, 1, code), 3, 1, code) : encrypt(name, 2, 4, code));

/**
 * 電話
 * @param {string} phone 手機或電話號碼
 * @param {string} code 隱碼替換元素
 * @returns {string} 隱碼後資料
 */
export const encryptPhone = (phone = '', code = '*') => encrypt(phone, 4, 3, code);

/**
 * 身分證號或護照號碼
 * @param {string} id 台灣身分證號或護照號碼
 * @param {string} code 隱碼替換元素
 * @returns {string} 隱碼後資料
 */
export const encryptID = (id = '', code = '*') => (isTaiwanID(id) ? encrypt(id, 5, 3, code) : encrypt(id, 1, 3, code));

/**
 * 地址隱碼
 * @param {string} address 地址
 * @param {string} code 隱碼替換元素
 * @returns {string} 隱碼後資料
 */
export const encryptAddress = (address = '', code = '*') => {
  const isChineseAddress = /([\u4e00-\u9fa5\u3400-\u4DBF])/.test(address.replace(/ /g, ''));
  const encode = code.repeat(3);
  const engEncode = code.repeat(5);

  if (isChineseAddress) {
    return address.replace(/^(.*)(.{3})(.{3})$/, (a, b, c, d) => `${b}${encode}${d}`);
  }

  // 英文地址 (頭尾帶換掉 5 字隱碼)
  return address.replace(/^(.{5})(.*)(.{5})$/, (a, b, c) => `${engEncode}${c}${engEncode}`);
};

export default {
  encryptAccount,
  encryptName,
  encryptPhone,
  encryptID,
  encryptAddress,
};
