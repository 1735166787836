<template>
  <BannerBox :show-banner="hasItems" :title="title" class="carousel">
    <template #subTitle>
      <RouterLinkUsage v-if="!isEmptyValue(link)" :link="link" title="看更多" class="more-link">看更多</RouterLinkUsage>
    </template>
    <template #body>
      <div v-if="itemRows.length > 0" class="box">
        <swiper ref="controlledSwiper" :options="swiperOptions">
          <swiper-slide v-for="(row, index) in itemRows" :key="index" class="brand-row">
            <router-link-usage v-for="item in row" :key="item.id" :link="item.link" :title="item.alt" class="brand"><img v-lazy="item.image" :alt="item.alt" /></router-link-usage>
          </swiper-slide>
          <div v-if="useSwiper" class="swiper-button-prev"></div>
          <div v-if="useSwiper" class="swiper-button-next"></div>
        </swiper>
      </div>
    </template>
  </BannerBox>
</template>

<script setup>
import { ref, toRefs, computed, onMounted, watch } from 'vue';
import BannerBox from '@/components/layout/banner-box-2024index.vue';
import RouterLinkUsage from '@/components/utility/router-link-usage.vue';
import { to2DArray } from '@/helper/grid';
import { isEmptyValue } from '@/helper/data-process';

const swiperOptions = {
  loop: true,
  // autoplay: { delay: 3000, disableOnInteraction: false },
  enabled: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
};

const props = defineProps({
  title: { type: String, default: '' },
  items: { type: Object, required: true },
});

const { title, items } = toRefs(props);
const itemRows = ref([]);
const hasItems = computed(() => itemRows.value.length > 0);
const useSwiper = computed(() => itemRows.value.length > 1);
const link = computed(() => items.value.link || '');

onMounted(() => {
  watch(
    items,
    async (nv) => {
      if (isEmptyValue(nv?.items)) return false;
      itemRows.value = await to2DArray(nv?.items, 8);
      swiperOptions.enabled = useSwiper.value;
      return true;
    },
    { immediate: true, deep: true },
  );
});
</script>

<style scoped>
@import url('@/assets/global-style/swiper.css');

.tw-container {
  @apply tw-w-[1280px] tw-p-0 tw-m-0;
}

.more-link {
  color: theme('colors.gold-300');
  @apply tw-text-base tw-leading-[22px] tw-font-medium;
}

.box {
  @apply tw-w-full;
  background-color: theme('colors.gray-f4');
}

.brand {
  @apply tw-w-[140px] tw-h-[140px] tw-flex;
}

.swiper-slide {
  @apply tw-px-6 tw-py-10;
  :deep(img) {
    @apply tw-rounded-lg;
  }
}

.brand-row {
  @apply tw-grid tw-gap-4 tw-grid-cols-8;
}

.carousel:hover {
  .swiper-button-prev,
  .swiper-button-next {
    @apply tw-flex;
  }
}
</style>
