export default function linkCrossoriginMiddleware({ to }) {
  // 找到所有的 link 標籤
  const linkElements = document.getElementsByTagName('link');

  // 為每個 link 標籤加上 crossorigin 屬性
  Array.from(linkElements).forEach((link) => {
    // 只為 stylesheet 和 preload 類型添加
    if (link.rel === 'stylesheet' || link.rel === 'preload') {
      link.setAttribute('crossorigin', 'anonymous');
    }
  });

  // 監聽動態添加的 link 標籤
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      mutation.addedNodes.forEach((node) => {
        if (node.tagName === 'LINK') {
          if (node.rel === 'stylesheet' || node.rel === 'preload') {
            node.setAttribute('crossorigin', 'anonymous');
          }
        }
      });
    });
  });

  observer.observe(document.head, {
    childList: true,
    subtree: true,
  });

  return Promise.resolve();
}
