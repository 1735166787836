import { fetchRecommendWords } from '@/api/search';

const get = (keyword, abortController) => {
  return fetchRecommendWords({ q: keyword }, abortController).then((res) => ({
    keyword,
    list: res?.data?.keyword || [],
  }));
};

export default {
  name: 'AutoCompleteService',
  get,
};
