import { includes } from 'ramda';
import { LocalStorageKey } from '@/constant/local-storage';
import BaseLocalStorage from '@/composables/utility/base-local-storage';

export default class LocalRecommendEventService extends BaseLocalStorage {
  constructor(localStorageService) {
    /** localStorageService */
    const keyName = `${LocalStorageKey.recommendEvent}_v1`;
    super(localStorageService, keyName);
    this.localStorageService = localStorageService;
  }

  #getKeywordOrder(keyword, list) {
    // 從文字反查 list
    if (includes(keyword, list)) return list.indexOf(keyword) + 1; // 起始值 = 0
    return -1;
  }

  save(keyword, token, list) {
    if (!this.isSupport()) return;

    const formatted = {
      keyword,
      token,
      order: this.#getKeywordOrder(keyword, list),
    };
    this.localStorageService.set(this.key, formatted);
  }
}
