<template>
  <BannerBoxMobile :show-banner="hasTabs" :title="title">
    <template #body>
      <ul v-if="tabs.length > 1" class="tabs">
        <li v-for="(tab, index) in formattedTabs" :key="index" :class="{ active: currentTab === tab.index, empty: !tab.title }" @click="tab.index !== undefined && selectTab(tab.index)">
          {{ tab.title || '' }}
        </li>
      </ul>
      <div v-if="isTabActive(currentTab) && !$isEmpty(tabs[currentTab]?.products)" ref="productsBlockElement" class="products-wrapper">
        <RowProducts :products="tabs[currentTab]?.products" />
      </div>
    </template>
  </BannerBoxMobile>
</template>

<script setup>
import { ref, toRefs, computed, watch, onMounted } from 'vue';
import { map } from 'ramda';
import { usePriceAndImageStore } from '@/stores/price-and-image.js';
import BannerBoxMobile from '@/components/layout/elements/mobile/banner-box-mobile-2024index.vue';
import RowProducts from '@/components/layout/elements/mobile/row-products.vue';

const props = defineProps({
  title: { type: String, default: '' },
  tabs: { type: Array, required: true },
});

const { title, tabs } = toRefs(props);
const priceAndImage = usePriceAndImageStore();
const { fetchPrice } = priceAndImage;

const currentTab = ref(0);
const loadedTabs = ref(new Set());

const getProductPrices = () => {
  const ids = map((x) => x.esliteSN || x.guid || x.product_guid, tabs.value[currentTab.value]?.products || []);
  if (ids.length > 0) fetchPrice({ productIds: ids });
};

const productsBlockElement = ref(null);

const selectTab = (index, isClick = true) => {
  if (currentTab.value === index && isClick === true) return;
  currentTab.value = index;
  loadedTabs.value.add(index);
  getProductPrices();

  if (productsBlockElement?.value) {
    productsBlockElement.value.scroll({ left: 0, behavior: 'smooth' });
  }
};

/** @const {function} tabsChange tabs 載入時需要做的部分 */
const tabsChange = (nv) => {
  if (nv === undefined) return;
  currentTab.value = 0;
  selectTab(currentTab.value, false);
};

const isTabActive = (index) => loadedTabs.value.has(index);

const hasTabs = computed(() => tabs.value.length > 0 && tabs.value[0]?.products.length > 0);

const insertSpaces = (arr) => {
  return arr.length > 1
    ? arr.reduce((acc, item, index) => {
        if (index > 0) acc.push({ title: '', index: undefined });
        acc.push({ title: item.title, index });
        return acc;
      }, [])
    : arr.map((item, index) => ({ title: item.title, index }));
};

const formattedTabs = computed(() => insertSpaces(tabs.value));

onMounted(() => {
  watch(tabs, tabsChange, { immediate: true, deep: true });
});
</script>

<style scoped>
.tabs {
  list-style: none;
  display: flex;
  padding: 0;
  margin-bottom: 20px;
  overflow: auto;
}
.tabs li {
  cursor: pointer;
  flex-shrink: 0;
  @apply tw-p-[8px] tw-text-[14px] tw-leading-[22px] tw-font-medium tw-rounded;
  color: theme('colors.gray-400');
  &.active {
    background: theme('colors.gold-300');
    color: theme('colors.white');
  }
  &.empty {
    cursor: default;
    color: theme('colors.gray-200');
    line-height: 18px;
    &::before {
      font-size: 10px;
      content: '';
      border-right: 1px solid theme('colors.gray-200');
    }
  }
}
.products-wrapper {
  overflow: auto;
}
</style>
