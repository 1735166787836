<template>
  <div v-if="hasTabs" class="banner-box B015">
    <div class="title-box">
      <div class="title">{{ title }}</div>
      <RouterLinkUsage link="/best-sellers/online" title="網路暢榜" class="more-link">看更多</RouterLinkUsage>
    </div>
    <div v-if="chartWithTitleTranslation?.length > 0" class="tabs">
      <div class="wrapper">
        <div v-for="(tab, index) in chartWithTitleTranslation" :key="index" :attr-category-id="tab.categoryId" class="tab">
          <div class="sub-title">{{ tab.title || tab.categoryId || ' ' }}</div>
          <div class="product-box">
            <Card v-for="(product, pindex) in (tab.products || []).slice(0, 3)" :key="pindex" :product="product" :num="pindex + 1" class="product" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else></div>
</template>

<script setup>
import { toRefs, computed, watch, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useCategoriesStore } from '@/stores/categories';
import { useHomeAdStore } from '@/stores/home-ad';
import { usePriceAndImageStore } from '@/stores/price-and-image';
import RouterLinkUsage from '@/components/utility/router-link-usage.vue';
import Card from '@/components/product/card-horizontal.vue';
import { formatCategoryL1Data, sortByTitle } from '@/helper/category/category-data';
import { isEmptyValue } from '@/helper/data-process';

const props = defineProps({ title: { type: String, default: '' } });
const { title } = toRefs(props);

const categoriesStore = useCategoriesStore();
const { categoriesQueryGetter, getCategories } = storeToRefs(categoriesStore);
const homeAdStore = useHomeAdStore();
const { getOnlineLeaderboard } = storeToRefs(homeAdStore);
const priceStore = usePriceAndImageStore();
const { fetchPrice } = priceStore;

/** @const {function} extractEsliteSN 取得 esliteSN 組成 2d array */
const extractEsliteSN = (items) => {
  const allSNs = [...new Set(items.flatMap((item) => item.products.map((product) => product.esliteSN)).filter((sn) => sn))].sort((a, b) => a.localeCompare(b));

  // 拆分成最大長度 20 的 2D array
  const chunkedArray = [];
  for (let i = 0; i < allSNs.length; i += 20) chunkedArray.push(allSNs.slice(i, i + 20));

  return chunkedArray;
};

const chartWithTitleTranslation = computed(() => {
  const items = getOnlineLeaderboard.value?.items;
  const categories = getCategories.value;

  if (items && categories.length > 0) {
    return sortByTitle(
      items
        .map((item) => {
          const l1Category = categoriesQueryGetter.value(parseInt(item.title, 10));
          const { categoryKey, categoryTranslation } = formatCategoryL1Data(l1Category);
          const { products } = item;
          // 調整每個 product 並返回修改後的 products
          const updatedProducts = products.map((product) => ({ ...product, title: product.name }));

          return { ...item, products: updatedProducts, categoryId: item.title, gtmKey: categoryKey, title: categoryTranslation };
        })
        .filter((item) => !isEmptyValue(item.title)),
    ).slice(0, 14);
  }

  return [];
});

const hasTabs = computed(() => (chartWithTitleTranslation.value || []).length > 0 && chartWithTitleTranslation.value[0]?.products.length > 0);

/** @const {function} tabsChange tabs 載入時需要做的部分 */
const tabsChange = (nv) => {
  if (nv === undefined) return false;
  const ids2d = extractEsliteSN(chartWithTitleTranslation.value);
  if (ids2d.length > 0) ids2d.forEach((ids) => fetchPrice({ productIds: ids }));
  return true;
};

onMounted(() => {
  watch(chartWithTitleTranslation, tabsChange, { immediate: true, deep: true });
});
</script>

<style scoped>
.banner-box {
  @apply tw-w-full tw-bg-white tw-mb-4;
  border-bottom: 1px solid theme('colors.gray-100');
}

.title-box {
  @apply tw-px-4 tw-py-5;
  @apply tw-flex tw-justify-between tw-items-center;

  .title {
    @apply tw-text-[20px] tw-leading-6 tw-font-bold;
  }
}
.more-link {
  color: theme('colors.gold-300');
  @apply tw-text-sm tw-font-medium;
}

.tabs {
  @apply tw-w-full;

  .wrapper {
    @apply tw-w-full tw-my-4 tw-flex tw-flex-row tw-overflow-auto;
  }

  .tab {
    @apply tw-w-[72%] tw-ml-4;

    &:last-child {
      @apply tw-mr-4;
    }
  }

  .sub-title {
    @apply tw-mb-4;
    color: theme('colors.gray-300');

    &::before {
      @apply tw-mr-3 tw-text-sm tw-align-text-top;
      content: '|';
      color: theme('colors.gray-200');
    }
  }
}

.product-box {
  @apply tw-p-3 tw-m-0 tw-rounded-lg;
  @apply tw-flex tw-flex-col tw-justify-start;
  background: theme('colors.gold-50');

  .product {
    @apply tw-mb-3 tw-rounded-lg;

    &:last-child {
      @apply tw-mb-0;
    }
  }
}
</style>
