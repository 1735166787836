<template>
  <div v-if="notInlineApp" ref="headerFixedWrapperRef" class="header header-mobile">
    <!-- download app -->
    <a v-if="false" :href="appRedirectLink()" title="APP下載" target="_blank" class="download-app">
      <img src="@/static/images/app-icon.png" alt="誠品線上" />
      <div class="txt">現在下載誠品 APP ，拿限定免運券！</div>
      <div class="gold"><span>立即體驗</span></div>
    </a>

    <!-- search -->
    <HeaderSearchBar />
  </div>
  <div class="nbsp" :style="`height: ${height}px`">&nbsp;</div>
  <full-banner />
</template>

<script setup>
import { ref, computed, onUnmounted, onBeforeMount, onMounted } from 'vue';
import { useCartItemsStore } from '@/stores/cart-items';
import { useHomeAd2024Store } from '@/stores/home-ad-2024';
import { homePageADTypeEnum } from '@/constant/ad/homepage-ad-type';
import HeaderSearchBar from '@/components/layout/elements/mobile/header-searchbar.vue';
import FullBanner from '@/components/layout/full-banner.vue';
import checkIsInlineApp from '@/helper/check-inline-app';

const notInlineApp = ref(false);
const isLoaded = ref(false);
const headerFixedWrapperRef = ref(null);

// store
const homeAdStore = useHomeAd2024Store();
const { fetchHomeAd } = homeAdStore;
const cartItemsStore = useCartItemsStore();
const { initCartQuantity, clearQuantity } = cartItemsStore;

const appRedirectLink = () => {
  const ua = navigator.userAgent;
  if (ua.match(/(iPad|iPhone|iPod)/i)) return 'https://apps.apple.com/tw/app/id1527231018';
  if (ua.match(/Android/i)) return 'https://play.google.com/store/apps/details?id=com.eslite.tw';
  return 'https://vip.eslite.com/esliteapp/tw/';
};

const height = computed(() => headerFixedWrapperRef.value?.clientHeight || 0);

onBeforeMount(() => {
  // 不是 app 內置瀏覽器？
  notInlineApp.value = !checkIsInlineApp();
  isLoaded.value = true;
});
onMounted(() => {
  initCartQuantity();
  // B007, A001
  fetchHomeAd([homePageADTypeEnum.bigSlide, homePageADTypeEnum.eightLinks]);
});
onUnmounted(() => {
  clearQuantity();
});
</script>

<script>
export default { name: 'HeaderWith2024Mobile' };
</script>

<style scoped>
.header.header-mobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.download-app {
  @apply tw-h-[52px] tw-px-4 tw-py-[6px] tw-text-[13px] tw-leading-[18px] tw-font-normal tw-grid tw-gap-2 tw-no-underline;
  grid-template-columns: auto 1fr auto;
  color: theme('colors.gray-800');
  background-color: theme('colors.white');
  img {
    @apply tw-w-10 tw-h-10 tw-rounded-lg;
  }
  .txt {
    @apply tw-grid tw-items-center tw-overflow-hidden;
  }
  .gold {
    @apply tw-grid tw-items-center;
    color: theme('colors.gold-500');
  }

  &:hover .gold span,
  &:active .gold span {
    border-bottom: 1px solid theme('colors.gold-500');
  }
}
</style>
