<template>
  <div v-if="haveCouponBanner" class="tw-p-0 tw-mb-4">
    <swiper ref="controlledSwiper" :options="swiperOptions" class="swiper">
      <swiper-slide v-for="(item, index) in getCouponBanner.items" :key="index">
        <router-link-usage :link="item.link" :title="item.alt" target="_blank" class="tw-block"><img :src="item.image" :alt="item.alt" class="tw-w-full" /></router-link-usage>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useHomeAd2024Store } from '@/stores/home-ad-2024';
import RouterLinkUsage from '@/components/utility/router-link-usage.vue';

const swiperOptions = {
  slidesPerView: 1,
  spaceBetween: 16,
  slidesPerGroup: 1,
  loop: true,
  autoplay: { delay: 3000, disableOnInteraction: false },
};

const homeAdStore = useHomeAd2024Store();
const { getCouponBanner } = storeToRefs(homeAdStore);

/** @const {computeed(boolean)} haveCouponBanner 是否有領券橫幅 banner */
const haveCouponBanner = computed(() => (getCouponBanner.value?.items || []).length > 0);
</script>
