<template>
  <BannerBoxMobile :show-banner="true" :title="title">
    <template #body>
      <div v-if="!$isEmpty(tabs)" class="products-wrapper">
        <RouterLinkUsage v-for="(tab, index) in tabs" :key="index" :link="tab.link" :title="tab.alt || tab.title">
          <img v-lazy="tab.image" v-default-image="defaultImage" :src="tab.image" :alt="tab.alt || tab.title" />
        </RouterLinkUsage>
      </div>
      <div v-else class="loading logo-loading tw-w-full tw-pb-[35%]">&nbsp;</div>
    </template>
  </BannerBoxMobile>
</template>

<script setup>
import { ref, toRefs, computed, onMounted } from 'vue';
import BannerBoxMobile from '@/components/layout/elements/mobile/banner-box-mobile-2024index.vue';
import RouterLinkUsage from '@/components/utility/router-link-usage.vue';
import { productDefaultWhiteImg } from '@/constant/images-path';

const defaultImage = ref(productDefaultWhiteImg);

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  tabs: {
    type: Array,
    required: true,
  },
});

const { title, tabs } = toRefs(props);
const hasTabs = computed(() => tabs.value.length > 0);

onMounted(() => {});
</script>

<style scoped>
.products-wrapper {
  @apply tw-overflow-auto tw-flex tw-flex-row tw-justify-start;

  a {
    @apply tw-mr-2 tw-block;

    &:last-child {
      @apply tw-mr-0;
    }

    img {
      @apply tw-w-[136px] tw-h-[200px] tw-rounded-lg;
    }
  }
}
</style>
