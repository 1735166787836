/**
 * loading 狀態
 * @type {object} loadingStatusType
 */
export const loadingStatusType = {
  wait: 'wait', // loading
  empty: 'empty', // 無資料
  full: 'full', // 有資料
  error: 'error', // api 錯誤
};
