<template>
  <!-- old -->
  <div v-if="componentName === 'homeLayout'" class="d-flex flex-column tw-overflow-x-hidden" style="min-height: 100vh" :class="{ bgc: isMemberProfileUrl }">
    <customer-service v-if="isShowCustomerService" :customer-service-url="isCustomerServiceUrl" :get-customer-status="getCustomerStatus" />
    <eslite-header :class="{ 'bg-white': isMemberProfileUrl }" />
    <router-view :key="$route.path" style="flex: 1; min-height: 50vh" />
    <eslite-footer />
    <loading v-if="getLoadingStatus" />
  </div>
  <!-- new desktop -->
  <div v-if="componentName === 'home2024indexLayout'" ref="appElem" class="d-flex flex-column" style="min-height: 100vh" :class="{ bgc: isMemberProfileUrl, sticky }">
    <customer-service v-if="isShowCustomerService" :customer-service-url="isCustomerServiceUrl" :get-customer-status="getCustomerStatus" />
    <eslite-index-header :scroll-mode="sticky" :have-top-banner="haveTopBanner" :top-bannr-image-source="topBannrImageSource" :small-bannr-image-source="smallBannrImageSource" />
    <div class="content-spacer"></div>
    <router-view :scroll-mode="sticky" />
    <loading v-if="getLoadingStatus" />
    <EsliteFooter2024Index />
  </div>
  <!-- new mobile -->
  <div v-if="componentName === 'home2024mobileLayout'" ref="appElem" class="d-flex flex-column tw-mb-[72px]" style="min-height: 100vh" :class="{ bgc: isMemberProfileUrl, sticky }">
    <customer-service v-if="isShowCustomerService" :customer-service-url="isCustomerServiceUrl" :get-customer-status="getCustomerStatus" />
    <eslite-mobile-header />
    <div class="content-spacer"></div>
    <router-view :scroll-mode="sticky" />
    <loading v-if="getLoadingStatus" />
    <EsliteFooter2024Mobile />
    <TabBar2024Mobile />
  </div>
</template>

<script setup>
import { ref, computed, defineAsyncComponent, watch, onMounted, onUnmounted } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';

import { useLoadingStore } from '@/stores/loading';
import { useCustomerServiceStore } from '@/stores/customer-service';
import { useHomeAd2024Store } from '@/stores/home-ad-2024';
import { useCategoriesStore } from '@/stores/categories';

import { useGetCustomerServiceStore } from '@/composables/store/customer-service';

import EsliteHeader from '@/components/layout/header.vue';
import EsliteIndexHeader from '@/components/layout/header-2024index.vue';
import EsliteMobileHeader from '@/components/layout/header-2024mobile.vue';
import EsliteFooter from '@/components/layout/footer.vue';
import EsliteFooter2024Index from '@/components/layout/footer-2024index.vue';
import EsliteFooter2024Mobile from '@/components/layout/footer-2024mobile.vue';
import TabBar2024Mobile from '@/components/layout/tab-bar.vue';
import Loading from '@/components/utility/loading.vue';

import { filterUrl } from '@/constant/customer-service';
import { memberProfileStyle } from '@/constant/member-profile';

import { fixedScrollAddListeners, fixedScrollRemoveListeners } from '@/helper/fixed-scrolling';
import { isMobile, isTablet, isDesktop } from '@/helper/ua.js';

const CustomerService = defineAsyncComponent(() => import('@/components/customer-service/customer-service.vue'));

const route = useRoute();
const categoriesStore = useCategoriesStore();
const { fetchCategories, fetchBrandGroup } = categoriesStore;
const loadingStore = useLoadingStore();
const { getLoadingStatus } = storeToRefs(loadingStore);
const customerServiceStore = useCustomerServiceStore();
const { getCustomerStatus } = useGetCustomerServiceStore();
const homeAdStore = useHomeAd2024Store();
const { getTopBanner, getHeaderSmallBanner } = storeToRefs(homeAdStore);

const isMobileRef = isMobile();
const isTabletRef = isTablet();
const isDesktopRef = isDesktop();

/** @const {computed({})} topBannrImageSource 置頂 banner data */
const topBannrImageSource = computed(() => getTopBanner.value);

/** @const {computed({})} smallBannrImageSource header small banner data */
const smallBannrImageSource = computed(() => getHeaderSmallBanner.value);

/** @const {computeed(boolean)} haveTopBanner 是否有置頂 banner */
const haveTopBanner = computed(() => !!topBannrImageSource.value?.image);

const sticky = ref(false);
const appElem = ref(null);

const turnOnCustomerStatus = () => customerServiceStore.setCustomerStatus(true);
const isMemberProfileUrl = computed(() => memberProfileStyle.includes(route.fullPath));
const isCustomerServiceUrl = computed(() => filterUrl.includes(route.fullPath));
const isShowCustomerService = computed(() => getCustomerStatus.value || isCustomerServiceUrl.value);

const componentName = computed(() => {
  const isNewIndex = route.query?.type === 'newindex';
  // 虛擬貨架 (新版)
  if (isNewIndex && route.query?.layout === 'virtual_shelf') return 'home2024mobileLayout';
  if (isNewIndex && (isMobileRef || isTabletRef)) return 'home2024mobileLayout';
  if (isNewIndex && isDesktopRef) return 'home2024indexLayout';
  // old index or default
  return 'homeLayout';
});

watch(
  isCustomerServiceUrl,
  (newValue) => {
    if (newValue) turnOnCustomerStatus();
  },
  { immediate: true },
);
onMounted(() => {
  fixedScrollAddListeners('#header-hidden-anchor', appElem, sticky);
  fetchCategories();
  fetchBrandGroup();
});
onUnmounted(fixedScrollRemoveListeners);
</script>
