<template>
  <component :is="componentName" />
</template>

<route>{ meta: { layout: "homeBase" } }</route>

<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import IndexComponent from '@/components/home/index.vue';
import NewIndexDesktopComponent from '@/components/home/index2024.vue';
import NewIndexMobileComponent from '@/components/home/mobile2024.vue';
import { isMobile, isTablet, isDesktop } from '@/helper/ua.js';

const route = useRoute();

const isMobileRef = isMobile();
const isTabletRef = isTablet();
const isDesktopRef = isDesktop();

/**
 * @const {computed} componentName use component
 * @description ?type=newindex 新版首頁 (裝置判斷 desktop/mobile) NewIndexDesktopComponent/NewIndexMobileComponent
 * ?type=newindex&layout=virtual_shelf 新版首頁 (虛擬貨架, 使用 mobile component) NewIndexMobileComponent
 * 非 type=newindex 時使用舊規則 IndexComponent
 */
const componentName = computed(() => {
  const isNewIndex = route.query?.type === 'newindex';
  // 虛擬貨架 (新版)
  if (isNewIndex && route.query?.layout === 'virtual_shelf') return NewIndexMobileComponent;
  if (isNewIndex && (isMobileRef || isTabletRef)) return NewIndexMobileComponent;
  if (isNewIndex && isDesktopRef) return NewIndexDesktopComponent;
  // old index or default
  return IndexComponent;
});
</script>

<script>
export default {
  name: 'Index',
};
</script>
