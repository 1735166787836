// package
import { useRoute } from 'vue-router';
import { ifElse, includes } from 'ramda';

// service
import AbTestService from '@/services/utility/ab-test-service';

// constant
import { abConfigArguments, generateTypesEnum } from '@/constant/ab-test/ab-test';
import { abTestCookies } from '@/constant/ab-test/cookies-collection';
import { expEnum, expSearchVersionEnum } from '@/constant/search/search';

export function useSearchAb() {
  /**
   * vue instance
   */
  const route = useRoute();
  /**
   * A/B Testing
   * */
  const abService = new AbTestService(generateTypesEnum.probability, abTestCookies.search);
  const inputExp = route.query.exp || abService.getExperience(...abConfigArguments.search);
  const exp = ifElse(
    // is category
    (x) => includes('category', x.name),
    // true: 分類頁走宇匯
    () => expEnum.origin,
    // false: 搜尋頁走 a/b
    () => inputExp,
  )(route);

  const version = expSearchVersionEnum[exp] || expSearchVersionEnum[expEnum.origin];

  // user 如果強制給 exp 版本就按 user 給的，寫入 cookies，以免跳來跳去
  if (exp) {
    abService.overwriteABTestCookies(exp);
  }

  /**
   * return
   * */
  return {
    apiVersion: version,
    exp,
  };
}
