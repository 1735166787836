<template>
  <BannerBox :show-banner="hasSlide" :title="title" class="carousel">
    <template #body>
      <swiper ref="controlledSwiper" :options="swiperOptions" class="swiperBankSlide">
        <swiper-slide v-for="(item, index) in layout" :key="`bank-slide-${index}`" class="slide-wrapper" :data-number="index">
          <router-link-usage :link="item.link" :title="item.alt" class="slide-inner">
            <img v-lazy="item.image" v-default-image="defaultImage" :alt="item.alt" class="bank-img" />
          </router-link-usage>
        </swiper-slide>
        <div v-if="layout.length > 5" class="swiper-button-prev"></div>
        <div v-if="layout.length > 5" class="swiper-button-next"></div>
      </swiper>
    </template>
  </BannerBox>
</template>

<script setup>
import { ref, toRefs, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useHomeAd2024Store } from '@/stores/home-ad-2024';
import BannerBox from '@/components/layout/banner-box-2024index.vue';
import RouterLinkUsage from '@/components/utility/router-link-usage.vue';
import { productDefaultWhiteImg } from '@/constant/images-path';
import { adjustArray } from '@/helper/grid';

const defaultImage = ref(productDefaultWhiteImg);
const homeAdStore = useHomeAd2024Store();
const { getBankCardSlide } = storeToRefs(homeAdStore);
const slides = computed(() => (getBankCardSlide.value?.items || []).slice(0, 5));
const layout = computed(() => adjustArray(slides.value, 5));
const hasSlide = computed(() => slides.value.length > 0);

const props = defineProps({
  title: { type: String, default: '' },
});

const { title } = toRefs(props);

const controlledSwiper = ref(null);

const swiperOptions = {
  slidesPerView: 4.5,
  spaceBetween: 16,
  slidesPerGroup: 1,
  loop: true,
  // autoplay: { delay: 3000, disableOnInteraction: false },
  navigation: { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' },
};
</script>

<style scoped>
@import url('@/assets/global-style/swiper.css');

.swiperBankSlide {
  @apply tw-px-[44px];
  background-color: theme('colors.gray-f4');
}

.slide-wrapper {
  @apply tw-w-[260px] tw-h-[150px] tw-mt-4 tw-mb-[14px];
  .slide-inner {
    @apply tw-w-[260px] tw-h-[150px] tw-block;

    .bank-img {
      @apply tw-w-[260px] tw-h-[150px] tw-rounded-lg;
    }
  }
}

.carousel:hover {
  .swiper-button-prev,
  .swiper-button-next {
    display: flex;
  }
}
</style>
