import { fetchRecommendWords } from '@/api/search';
import { searchVersion } from '@/constant/search/search';

const createParams = (keyword) => {
  return {
    q: keyword,
    dataset: 'cloud-retail',
  };
};

const get = (keyword, abortController) => {
  return fetchRecommendWords(createParams(keyword), abortController, searchVersion.v3).then((res) => {
    return {
      keyword,
      list: res?.data?.suggestions || [],
      token: res?.data?.attribution_token || '',
    };
  });
};

export default {
  name: 'GoogleAutoCompleteService',
  get,
};
