<template title="B016-B019 MultiCarouselDisplaySection">
  <div id="multi-carousel-display-section" class="tw-flex tw-w-full tw-pb-[64px]">
    <div class="tw-w-[624px] tw-mr-3">
      <div class="title">主題企劃</div>
      <div class="tw-mt-8 tw-h-[310px]" data-size="624x310">
        <BannerSwiper v-if="!isEmptyValue(mainTopicProjectBannerGroup)" :items="mainTopicProjectBannerGroup" class="tw-h-[310px]" />
        <div v-else class="loading logo-loading">&nbsp;</div>
      </div>
      <div v-if="!isEmptyValue(threeBannerGroup)" class="three-banner-group" data-size="624x300">
        <RouterLinkUsage v-for="(banner, index) in threeBannerGroup" :key="index" :link="banner.link" :title="banner.alt">
          <img :src="banner.image" />
        </RouterLinkUsage>
      </div>
      <div v-else class="three-banner-group">
        <div class="loading logo-loading">&nbsp;</div>
        <div class="loading logo-loading">&nbsp;</div>
        <div class="loading logo-loading">&nbsp;</div>
      </div>
    </div>
    <div class="tw-w-[632px] tw-ml-3">
      <div class="title">限定商品</div>
      <div class="limited-edition-group" data-size="632x310">
        <swiper v-if="!isEmptyValue(limitedEditionGroup)" ref="limitedEditionGroupSwiper" :options="swiperOptionWithThreeProduct">
          <swiper-slide v-for="product in limitedEditionGroup" :key="product.id">
            <card :product="product" />
          </swiper-slide>
          <div v-if="limitedEditionGroup.length > 3" class="swiper-button-prev"></div>
          <div v-if="limitedEditionGroup.length > 3" class="swiper-button-next"></div>
        </swiper>
        <div v-else class="loading logo-loading">&nbsp;</div>
      </div>
      <div class="title tw-mt-3.5">獨家品牌</div>
      <div class="exclusive-brand-group" data-size="632x200">
        <swiper v-if="!isEmptyValue(exclusiveBrandGroup)" ref="exclusiveBrandGroupSwiper" :options="swiperOptionWithFourBrand" @swiper="exclusiveBrandGroupOnSwiper">
          <swiper-slide v-for="(page, pageIndex) in exclusiveBrandGroup" :key="`brand-${pageIndex}`">
            <div class="tw-grid tw-gap-x-3 tw-grid-cols-4">
              <brand-card v-for="(brand, index) in page" :key="`brand-${pageIndex}-${index}`" :brand="brand" />
            </div>
          </swiper-slide>
        </swiper>
        <div v-else class="loading logo-loading">&nbsp;</div>
        <div v-if="exclusiveBrandGroup.length > 1" ref="exclusiveBrandGroupNextEl" class="swiper-button-next" @click="exclusiveBrandGroupNext"></div>
        <div v-if="exclusiveBrandGroup.length > 1" ref="exclusiveBrandGroupPrevEl" class="swiper-button-prev" @click="exclusiveBrandGroupPrev"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { map } from 'ramda';
import { useHomeAd2024Store } from '@/stores/home-ad-2024';
import { usePriceAndImageStore } from '@/stores/price-and-image';
import BannerSwiper from '@/components/utility/swiper/banner-swiper.vue';
import RouterLinkUsage from '@/components/utility/router-link-usage.vue';
import Card from '@/components/product/card-index2024.vue';
import BrandCard from '@/components/product/brand-card.vue';
import { isEmptyValue } from '@/helper/data-process';

/**
B019 獨家品牌區塊中台欄位設定：
共需設定12顆廣告版位
廣告名稱(1~12)：文字框，需寫入ALT標籤，上限30個字，需檢核濾除emoji符號
連結：輸入框
選擇檔案：按鈕，檢核條件：尺寸「136x200」｜40KB，圖檔限制：jpg、png、gif
 */

const limitedEditionGroupSwiper = ref(null);
const exclusiveBrandGroupSwiper = ref(null);
const exclusiveBrandGroupSwiperInstance = ref(null);
const exclusiveBrandGroupNextEl = ref(null);
const exclusiveBrandGroupPrevEl = ref(null);

const homeAdStore = useHomeAd2024Store();
const { getNewMainTopicProject, getThreeBannerGroup, getLimitedEditionGroup, getExclusiveBrandGroup } = storeToRefs(homeAdStore);
const priceAndImageStore = usePriceAndImageStore();
const { fetchPrice: fetchPriceAndImage } = priceAndImageStore;

/** B016 主題企劃 */
const mainTopicProjectBannerGroup = computed(() => (getNewMainTopicProject.value?.items || []).slice(0, 10));
/** B017 直式3banner */
const threeBannerGroup = computed(() => (getThreeBannerGroup.value?.items || []).slice(0, 3));
/** B018 限定商品 */
const limitedEditionGroup = computed(() => (getLimitedEditionGroup?.value?.items ? getLimitedEditionGroup.value?.items[0].products : [] || []).slice(0, 9));
/** B019 獨家品牌 */
const exclusiveBrandGroup = computed(() => {
  const data = (getExclusiveBrandGroup.value?.items || []).slice(0, 12);
  const pageSize = 4;
  return Array.from({ length: Math.ceil(data.length / pageSize) }, (_, i) => data.slice(i * pageSize, i * pageSize + pageSize));
});

/** @const {object} swiperOptionWithThreeProduct 3商品組合輪播 options */
const swiperOptionWithThreeProduct = {
  slidesPerView: 3,
  spaceBetween: 16,
  slidesPerGroup: 3,
  loop: true,
  autoplay: false,
  navigation: { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' },
};

/** @const {object} swiperOptionWithFourBrand 4品牌組合輪播 options */
const swiperOptionWithFourBrand = {
  slidesPerView: 1,
  spaceBetween: 16,
  slidesPerGroup: 1,
  loop: true,
  autoplay: false,
};

const exclusiveBrandGroupOnSwiper = (swiper) => (exclusiveBrandGroupSwiperInstance.value = swiper);
const exclusiveBrandGroupNext = () => exclusiveBrandGroupSwiperInstance.value.slideNext();
const exclusiveBrandGroupPrev = () => exclusiveBrandGroupSwiperInstance.value.slidePrev();

const getProductPrices = (ids = []) => {
  if (ids.length > 0) fetchPriceAndImage({ productIds: ids });
};

onMounted(() => {
  // 限定商品 取得金額圖片
  watch(limitedEditionGroup, (nv) => {
    if (nv.length > 0) {
      const ids = map((x) => x.esliteSN, nv || []);
      getProductPrices(ids);
    }
  });
});
</script>

<style scoped>
@import url('@/assets/global-style/swiper.css');

.title {
  @apply tw-h-[60px] tw-pt-2 tw-pb-5;
  @apply tw-text-[24px] tw-leading-7 tw-font-bold;
  border-bottom: 1px dashed theme('colors.gray-500');
}

.three-banner-group {
  @apply tw-mt-[38px] tw-h-[300px];
  @apply tw-grid tw-gap-x-3 tw-grid-cols-3;

  img {
    @apply tw-w-[200px] tw-h-[300px] tw-rounded-lg;
  }
}

.limited-edition-group {
  @apply tw-mt-8 tw-h-[310px];
}
.exclusive-brand-group {
  @apply tw-mt-8 tw-h-[232px] tw-relative;

  > div {
    @apply tw-rounded-[4px];
  }
  .swiper {
    @apply tw-p-4 tw-rounded-lg;
    background-color: theme('colors.gray-50');
  }
  .loading.logo-loading {
    @apply tw-h-[232px];
  }
}

.loading {
  &.three-banner-group {
    @apply tw-h-[300px];
  }
  &.limited-edition-group {
    @apply tw-h-[310px];
  }
  &.exclusive-brand-group {
    @apply tw-h-[200px];
  }
}

.hidden {
  @apply tw-hidden tw-invisible;
}
.exclusive-brand-group {
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }

  &:hover {
    .swiper-button-prev,
    .swiper-button-next {
      display: flex;
    }
  }
}
</style>
