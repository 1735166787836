<template>
  <metainfo></metainfo>
  <errorLayout v-if="isErrorLayout" :error="{ status: errorStatus }"></errorLayout>
  <virtual-shelf v-else-if="isVirtualShelfLayout" />
  <component :is="layout" v-else-if="!$isEmpty(layout)"></component>
</template>

<script>
import { useRoute } from 'vue-router';
import { computed, watch } from 'vue';
import { storeToRefs } from 'pinia';
import VirtualShelf from '@/layouts/virtual-shelf.vue';
import defaultLayout from '@/layouts/default.vue';
import testonlyLayout from '@/layouts/test-only.vue';
import blankLayout from '@/layouts/blank.vue';
import cartLayout from '@/layouts/cart.vue';
import errorLayout from '@/layouts/error.vue';
import esliteLayout from '@/layouts/eslite.vue';
import exhibitionLayout from '@/layouts/exhibition.vue';
import marketLayout from '@/layouts/market.vue';
import homeMemberProfileLayout from '@/layouts/home-member-profile.vue';
import homeLayout from '@/layouts/home.vue';
import giftAiLayout from '@/layouts/gift-ai.vue';
import homeBaseLayout from '@/layouts/home-base.vue';
import home2024indexLayout from '@/layouts/home-2024index.vue';
import home2024mobileLayout from '@/layouts/home-2024mobile.vue';
import { isEmptyValue } from '@/helper/data-process';
import { importDefaultGoogleFonts } from '@/helper/meta/google-fonts';
import { layoutErrorStatusEnum } from '@/router/layout';
import virtualShelfComposables from '@/composables/utility/virtual-shelf';
import { useLayoutErrorStore } from '@/stores/layout-error';

const components = {
  VirtualShelf,
  defaultLayout,
  blankLayout,
  cartLayout,
  errorLayout,
  esliteLayout,
  homeLayout, // 舊版 home
  homeBaseLayout, // 新版 home
  home2024indexLayout, // 首頁改版的 home layout
  home2024mobileLayout, // 首頁改版的 home mobile layout
  exhibitionLayout,
  homeMemberProfileLayout,
  testonlyLayout,
  marketLayout,
  giftAiLayout,
};

const metaInfo = function () {
  // 除了 index 以外的頁面都直接載入預設 google fonts
  if ((this.$route.name && this.$route.name !== 'index') || import.meta.env.VITE_TYPE === 'development') {
    importDefaultGoogleFonts();
    return {};
  }

  // 頁面載入完成後載入 google fonts
  window.addEventListener('load', () => importDefaultGoogleFonts());
  // 首頁特別先載入有帶 text 的 NotoSerifTC，減少首次進入頁面時載入不必要的 fonts 檔案
  const text = this.$screen.isMobileSize ? '發燒必買今日最推' : '發燒必買今日最推流行熱搜';
  return {
    link: [{ href: `https://fonts.googleapis.com/css2?family=Noto+Serif+TC:wght@700&subset=chinese-traditional&display=swap&text=${text}`, rel: 'stylesheet' }],
  };
};

const setup = () => {
  const route = useRoute();

  // ?layout=virtual_shelf
  const { isVirtualShelfLayout } = virtualShelfComposables();

  const layoutErrorStore = useLayoutErrorStore();
  const { getErrorStatus } = storeToRefs(layoutErrorStore);

  /**
   * Computed
   */
  const errorStatus = computed(() => getErrorStatus.value);
  const isErrorLayout = computed(() => errorStatus.value !== null);
  const layoutKey = computed(() => route.meta.layout || '');
  const hasLayout = computed(() => !isEmptyValue(layoutKey.value) && Object.keys(components).includes(`${layoutKey.value}Layout`));
  const layout = computed(() => (hasLayout.value ? `${layoutKey.value}Layout` || defaultLayout : ''));

  /**
   * Methods
   */
  const setErrorStatus = (error) => layoutErrorStore.setErrorStatus(error);
  const checkIsUnknownRoute = (route) => {
    if (route.path === '/' || isEmptyValue(route.path)) return false;
    return isEmptyValue(route.name);
  };

  watch(
    route,
    (val) => {
      if (checkIsUnknownRoute(val)) {
        setErrorStatus(layoutErrorStatusEnum.error404);
      }
    },
    {
      immediate: true,
    },
  );

  return {
    errorStatus,
    isErrorLayout,
    layout,
    isVirtualShelfLayout,
  };
};

export default {
  name: 'App',
  components,
  metaInfo,
  setup,
};
</script>
