import { UAParser } from 'ua-parser-js';

const parser = new UAParser();

// 針對 iPad Pro 的 user agent 特別處理
const isSpecificiPadPro = () => {
  const ua = navigator.userAgent;
  return ua.includes('Macintosh') && 'ontouchstart' in window; // iPad Pro 使用桌面版的 user agent 但具有觸控能力
};

// 檢查是否為桌面設備
export const isDesktop = () => {
  const deviceType = parser.getDevice().type;

  // 特別處理 iPad Pro
  if (isSpecificiPadPro()) {
    return false; // iPad Pro 應該被判斷為平板
  }

  // 判斷為桌面設備的條件
  return !deviceType || deviceType === 'desktop';
};

// 檢查是否為平板設備
export const isTablet = () => {
  const deviceType = parser.getDevice().type;

  // 特別處理 iPad Pro
  if (isSpecificiPadPro()) {
    return true; // iPad Pro 應該被判斷為平板
  }

  // 判斷為平板設備的條件
  return deviceType === 'tablet';
};

// 檢查是否為手機設備
export const isMobile = () => {
  return parser.getDevice().type === 'mobile';
};

// 預設匯出所有方法
export default {
  isMobile,
  isTablet,
  isDesktop,
};
