import { range } from 'ramda';

export const getBlankColumns = (numberOfColumns, listLength) => {
  if (!listLength) return [];
  const numberOfLastRowColumns = listLength % numberOfColumns;
  if (!numberOfLastRowColumns) return [];
  const numberOfBlanks = numberOfColumns - numberOfLastRowColumns;
  return range(0, numberOfBlanks);
};

/**
 * 一維轉二維陣列
 * @param {array} arr 陣列
 * @param {number} itemsPerRow 每一個 row 可裝載的內容數量
 * @returns {object[]} 2d array
 */
export const to2DArray = (arr, itemsPerRow = 8) => arr.reduce((rows, key, index) => (index % itemsPerRow === 0 ? rows.push([key]) : rows[rows.length - 1].push(key)) && rows, []);

/**
 * 畫面陣列長度補全
 * @param {array} arr array
 * @param {number} pageSize page size
 * @returns new array
 * 當陣列長度小於單頁數量 return arr
 * 當陣列長度在單頁數量和 2 倍單頁數量之間: 重複一次並截取到 2 * pageSize 的長度
 * 當陣列長度大於等於 2 倍單頁數量 return arr
 */
export const adjustArray = (arr = [], pageSize = 6) => (arr.length >= pageSize && arr.length < 2 * pageSize ? [...arr, ...arr] : arr);
