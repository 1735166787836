<template>
  <BannerBoxMobile :show-banner="!allEmpty" :title="title" class="carousel">
    <template #body>
      <div class="tw-w-full">
        <!-- B012 -->
        <div v-if="showBanner" class="tw-w-full tw-pb-4"><banner-swiper :items="memberOnlySlides" /></div>
        <div v-else class="loading logo-loading tw-w-full tw-pb-[41%] tw-mb-4">&nbsp;</div>
        <!-- B013 -->
        <div v-if="hasBanner" class="tw-w-full tw-grid tw-grid-cols-3 tw-gap-2 tw-pb-4">
          <div v-for="(item, index) in banners" :key="index" class="banner-column">
            <RouterLinkUsage v-if="!$isEmpty(item.link)" :link="item.link" :title="item.alt">
              <img v-lazy="item.image" v-default-image="defaultImage" :src="item.image" :alt="item.alt" />
            </RouterLinkUsage>
          </div>
        </div>
        <div v-else class="loading logo-loading tw-w-full tw-pb-[44%] tw-mb-4">&nbsp;</div>

        <div v-if="hasHorizontalBanner" class="horizontal-banner-column">
          <RouterLinkUsage :link="horizontalBanner.link" :title="horizontalBanner.alt" class="slide-banner-img">
            <img class="" :src="horizontalBanner.imageMobile" :alt="horizontalBanner.alt" />
          </RouterLinkUsage>
        </div>
        <div v-else class="loading logo-loading tw-w-full tw-pb-[41%]">&nbsp;</div>
      </div>
    </template>
  </BannerBoxMobile>
</template>

<script setup>
import { ref, toRefs, computed } from 'vue';
import BannerBoxMobile from '@/components/layout/elements/mobile/banner-box-mobile-2024index.vue';
import BannerSwiper from '@/components/utility/swiper/banner-swiper.vue';
import RouterLinkUsage from '@/components/utility/router-link-usage.vue';
import { productDefaultWhiteImg } from '@/constant/images-path';
import { isEmptyValue } from '@/helper/data-process';

const defaultImage = ref(productDefaultWhiteImg);
const props = defineProps({
  title: { type: String, default: '' },
  memberOnly: { type: Object, default: () => ({}) },
  slideAndBanner: { type: Object, default: () => ({}) },
});
const { title, memberOnly, slideAndBanner } = toRefs(props);

const memberOnlySlides = computed(() => memberOnly.value?.items || []);
const showBanner = computed(() => memberOnlySlides.value.length > 0 || false);
/** @const {computed} banners 直式banner 3個 */
const banners = computed(() => slideAndBanner.value?.items || []);
/** @const {computed} horizontalBanner 橫幅腰帶 banner */
const horizontalBanner = computed(() => slideAndBanner.value?.banner);
const hasBanner = computed(() => banners.value.length > 0);
const hasHorizontalBanner = computed(() => !isEmptyValue(horizontalBanner.value?.link));
const allEmpty = computed(() => showBanner.value === false && hasBanner.value === false && hasHorizontalBanner.value === false);
</script>

<style scoped>
@import url('@/assets/global-style/swiper.css');

.carousel {
  .swiper-button-prev,
  .swiper-button-next {
    display: flex;
  }
}

.banner-column {
  @apply tw-col-span-1;

  img {
    @apply tw-w-full tw-rounded-lg;
  }
}

.horizontal-banner-column {
  @apply tw-w-full tw-col-span-6;

  img {
    @apply tw-w-full tw-rounded-lg;
  }
}
</style>
