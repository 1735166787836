/** Fixed sticky header when scrolling */
import throttle from 'lodash-es/throttle';

/**
 * checkHeaderSticky
 * @param {ref(Boolean)} refData is scroll over offsetTop (boolean)
 * @param {number} offsetTop 定位元件與天花板之間的距離 (變形前)
 */
export const checkHeaderSticky = (anchor, refData, sticky) => {
  return throttle(function () {
    const hhaRef = document.querySelector(anchor) || null;
    if (hhaRef === null) {
      sticky.value = false;
      return false;
    }

    const y = -5;

    // fixed 狀態且 y=0 置頂時
    if (refData.value?.classList.contains('sticky') === true && hhaRef.getBoundingClientRect().y > y) {
      sticky.value = false;
    } else if (refData.value?.classList.contains('sticky') === false && hhaRef.getBoundingClientRect().y <= y) {
      sticky.value = true;
    }

    return true;
  }, 0);
};

/**
 * fixedScrollAddListeners 建立 fixed header 監聽事件
 * @param {string} anchor anchor line
 * @param {ref(Element)} refData is scroll over offsetTop (boolean)
 * @param {ref(Boolean)} offsetTop 定位元件與天花板之間的距離 (變形前)
 */
export const fixedScrollAddListeners = (anchor = '#header-hidden-anchor', refData, sticky) => {
  window.addEventListener('scroll', checkHeaderSticky(anchor, refData, sticky));
};

export const fixedScrollRemoveListeners = () => {
  window.removeEventListener('scroll', checkHeaderSticky);
};
