import generator from '@/helper/enum-generator';
import { icon711Img, iconFamilyMartImg, iconHiLifeImg, iconOKImg } from '@/constant/images-path';

export const deliveryTypes = [
  {
    name: '台灣7-ELEVEN取貨',
    code: '5',
    key: 'sevenEleven',
    type: 'ship_711',
    component: 'store-selector',
    sort: 1,
    oversea: false,
  },
  {
    name: '台灣全家/ OK /萊爾富 取貨',
    code: '3',
    key: 'convenienceStore',
    type: 'ship_cvs',
    component: 'store-selector',
    sort: 3,
    oversea: false,
  },
  {
    name: '全家取貨',
    code: '9',
    key: 'familyMart',
    type: 'ship_family_mart',
    component: 'store-selector',
    sort: 4,
    oversea: false,
  },
  {
    name: '台灣-宅配(台灣地區)',
    code: '2',
    key: 'home',
    type: 'ship_home',
    component: 'Address',
    sort: 5,
    oversea: false,
  },
  {
    name: '誠品門市取貨',
    code: '4',
    key: 'esliteStore',
    type: 'ship_eslite_store',
    component: 'esliteStore',
    sort: 7,
    oversea: false,
  },
  {
    name: '台灣以外快遞寄送',
    code: '1',
    key: 'aboard',
    type: 'ship_foreign',
    component: 'aboard-address',
    sort: 9,
    oversea: true,
  },
  {
    name: '香港取貨(OK便利店)',
    code: '7',
    key: 'hongKongStore',
    type: 'ship_hk',
    component: 'store-selector',
    sort: 11,
    oversea: true,
  },
  {
    name: '誠品基金會捐書',
    code: '8',
    key: 'donate',
    type: 'ship_donation',
    component: '',
    sort: 13,
    oversea: false,
  },
  {
    name: '電子配送',
    code: '11',
    key: 'online',
    type: 'ship_electronic',
    component: '',
    sort: 14,
    oversea: false,
  },
];

/**
 * 配送方式 `名稱`
 * {object} deliveryTypeName { type: name } 組合
 * @sample 'ship_home': '台灣-宅配(台灣地區)'
 * @type {{ ship_711: '台灣7-ELEVEN取貨' }, { ship_cvs: '台灣全家/ OK /萊爾富 取貨' }, { ship_family_mart: '全家取貨' }, { ship_home: '台灣-宅配(台灣地區)' }, { ship_eslite_store: '誠品門市取貨' }, { ship_foreign: '台灣以外快遞寄送' }, { ship_hk: '香港取貨(OK便利店)' }, { ship_donation: '誠品基金會捐書' }}
 */
export const deliveryTypeName = generator(deliveryTypes, 'type', 'name');

/**
 * 配送方式 `名稱`
 * {object} deliveryCodeName { code: name } 組合
 * @sample '2': 'ship_711'
 * @type {{ 5: ship_711' }}
 */
export const deliveryCodeName = generator(deliveryTypes, 'code', 'name');

/**
 * 配送方式 `type`
 * {object} deliveryCodeName { code: name } 組合
 * @sample '2': '台灣-宅配(台灣地區)'
 * @type {{ 5: '台灣7-ELEVEN取貨' }, { 3: '台灣全家/ OK /萊爾富 取貨' }, { 9: '全家取貨' }, { 2: '台灣-宅配(台灣地區)' }, { 4: '誠品門市取貨' }, { 1: '台灣以外快遞寄送' }, { 7: '香港取貨(OK便利店)' }, { 8: '誠品基金會捐書' }}
 */
export const deliveryCodeType = generator(deliveryTypes, 'code', 'type');

/**
 * 配送方式`值（code)`
 * {object} deliveryTypeEnum { key: code } 組合
 * @sample 'home': '2'
 * @type {{ sevenEleven: '5' }, { convenienceStore: '3' }, { familyMart: '9' }, { home: '2' }, { esliteStore: '4' }, { aboard: '1' }, { hongKongStore: '7' }, { donate: '8' }}
 */
export const deliveryTypeEnum = generator(deliveryTypes, 'key', 'code');

/**
 * 配送方式`值（type to code)`
 * {object} deliveryTypeToCodeEnum { type: code } 組合
 * @sample 'ship_home': '2'
 * @type {{ ship_711: '5' }, { ship_cvs: '3' }, { ship_family_mart: '9' }, { ship_home: '2' }, { ship_eslite_store: '4' }, { ship_foreign: '1' }, { ship_hk: '7' }, { ship_donation: '8' }}
 */
export const deliveryTypeToCodeEnum = generator(deliveryTypes, 'type', 'code');

/**
 * 配送方式`元件`
 * {object} deliveryTypeComponent { code: component } 組合
 * @sample '1': 'aboard'
 * @type {{ 5: 'store-selector' }, { 3: 'store-selector' }, { 9: 'store-selector' }, { 2: 'Address' }, { 4: 'esliteStore' }, { 1: 'aboard-address' }, { 7: 'store-selector' }, { 8: '' }}
 */
export const deliveryTypeComponent = generator(deliveryTypes, 'code', 'component');

/**
 * 配送方式`值（type)`
 * {object} deliveryTypeEnum { key: type } 組合
 * @sample 'sevenEleven': 'ship_711'
 * @type {{ sevenEleven: 'ship_711' }, { convenienceStore: 'ship_cvs' }, { familyMart: 'ship_family_mart' }, { home: 'ship_home' }, { esliteStore: 'ship_eslite_store' }, { aboard: 'ship_foreign' }, { hongKongStore: 'ship_hk' }, { donate: 'ship_donation' }, { online : 'ship_electronic'}}
 */
export const deliveryKeyToTypeEnum = generator(deliveryTypes, 'key', 'type');

/**
 * delivery type 對應 css class 及 icon 圖片
 * @return {[{code: Number, icons: [*], iconsActive: [*], class: String}]}
 */
export const deliveryTypeOptionBlock = [
  // 7-11
  {
    code: deliveryTypeEnum.sevenEleven,
    icons: [icon711Img],
    class: 'icon-7-11',
  },
  // 全家 OK 萊爾富
  {
    code: deliveryTypeEnum.convenienceStore,
    icons: [iconFamilyMartImg, iconOKImg, iconHiLifeImg],
    class: 'icon-familymart-ok-hilife',
  },
  // 全家
  {
    code: deliveryTypeEnum.familyMart,
    icons: [iconFamilyMartImg],
    class: 'icon-familymart',
  },
];
