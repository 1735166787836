const emptyCategoryL1 = {
  categoryTranslation: '',
  categoryKey: '',
  categoryColor: '',
};

export function formatCategoryL1Data(l1Category) {
  if (!l1Category) return emptyCategoryL1;
  return {
    categoryTranslation: l1Category.level1Description,
    categoryKey: l1Category.level1Key,
    categoryColor: l1Category.color,
  };
}

/** @const {string[]} sortOrder 排序順序 */
const sortOrder = ['電子書', '中文出版', '中文書', '外文出版', '外文書', '兒童親子', '童書', '親子用品', '文具潮藝', '食品日用', '食品保健', '生活家居', '美妝保養', '服飾鞋包', '休閒戶外', '3C家電', '3C', '家電', '影音'];
/** @const {string[]} ignoreOrder 排除內容 */
const ignoreOrder = ['日用'];

/** @const {method} sortByTitle 依照資料內 title 按 sortOrder 資料排序 */
export const sortByTitle = (dataArray) => {
  const filteredData = dataArray.filter((item) => !ignoreOrder.includes(item.title));

  return filteredData.sort((a, b) => {
    const indexA = sortOrder.indexOf(a.title);
    const indexB = sortOrder.indexOf(b.title);

    const rankA = indexA === -1 ? sortOrder.length : indexA;
    const rankB = indexB === -1 ? sortOrder.length : indexB;

    return rankA - rankB;
  });
};
