import { defineStore } from 'pinia';

const state = () => ({
  attributionToken: '',
});

const getters = {
  getAttributionToken: (state) => state.attributionToken,
};
const actions = {
  setAttributionToken(token) {
    this.attributionToken = token;
  },
};
export const useRetailSearchLogStore = defineStore('retailSearch', {
  state,
  getters,
  actions,
});
