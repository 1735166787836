<template>
  <BannerBoxMobile :show-banner="hasItems" :title="title" :full="true" class="carousel">
    <template #subTitle>
      <RouterLinkUsage v-if="!$isEmpty(link)" :link="link" title="看更多" class="more-link">看更多</RouterLinkUsage>
    </template>
    <template #body>
      <div v-if="hasItems" class="wrapper">
        <router-link-usage v-for="(item, index) in all" :key="index" :link="item.link" :title="item.alt" class="brand"><img v-lazy="item.image" :alt="item.alt" /></router-link-usage>
      </div>
    </template>
  </BannerBoxMobile>
</template>

<script setup>
import { toRefs, computed } from 'vue';
import BannerBoxMobile from '@/components/layout/elements/mobile/banner-box-mobile-2024index.vue';
import RouterLinkUsage from '@/components/utility/router-link-usage.vue';

const props = defineProps({
  title: { type: String, default: '' },
  items: { type: Object, required: true },
});

const { title, items } = toRefs(props);
const all = computed(() => items.value.items || []);
const hasItems = computed(() => all.value.length > 0);
const link = computed(() => items.value.link || '');
</script>

<style scoped>
@import url('@/assets/global-style/swiper.css');

.more-link {
  color: theme('colors.gold-300');
  @apply tw-text-base tw-leading-[22px] tw-font-medium;
}

.wrapper {
  @apply tw-p-4 tw-overflow-auto tw-flex tw-flex-row tw-justify-start;
  background-color: theme('colors.gray-50');

  a {
    @apply tw-mr-4 tw-block;

    &:last-child {
      @apply tw-mr-0;
    }

    img {
      @apply tw-w-[100px] tw-h-[100px] tw-rounded-lg;
    }
  }
}
</style>
