<template>
  <div v-if="display" class="complete-list" @mouseleave="leave">
    <div v-for="(item, index) in items" :ref="refName(index)" :key="`complete-list-${hover}-${index}`" class="item" :class="isHoverClass(index)" @click="select(item)" @mouseover="over(index)">
      <span v-if="sourceType === keywordTypeEnum.history" class="tw-mr-1 icon-searchhistory"></span>
      <span>{{ item.keyword }}</span>
      <span :class="{ bold: !$isEmpty(keyword) }">{{ item.otherWords }}</span>
    </div>
  </div>
</template>

<script>
import { length, splitAt, map, pipe, values, join } from 'ramda';
import { ref, toRefs, computed, watch } from 'vue';
import { hoverDefaultIndex, keywordTypeEnum } from '@/constant/search/auto-complete';

const props = {
  display: {
    type: Boolean,
    default: false,
  },
  list: {
    type: Array,
    default: () => [],
  },
  value: {
    type: Number,
    default: null,
  },
  keyword: {
    type: String,
    default: '',
  },
  sourceType: {
    type: String,
    default: keywordTypeEnum.none,
  },
};

const setup = (props, { emit }) => {
  const { keyword, list, value } = toRefs(props);
  const hoverItem = ref(hoverDefaultIndex);
  const renderCount = ref(0);

  const hover = computed({
    get() {
      return hoverItem.value;
    },
    set(value) {
      hoverItem.value = parseInt(value, 10);
    },
  });
  const items = computed(() =>
    pipe(
      // reset keywords format
      map((x) => splitAt(length(keyword.value))(x)),
      map((x) => ({ keyword: x[0], otherWords: x[1] })),
    )(list.value),
  );

  const emitCurrentItem = (index) => {
    emit('overItem', {
      index,
      text: list.value[index] || '', // using source
    });
  };
  // leave
  const leave = () => {
    hover.value = hoverDefaultIndex;
    emitCurrentItem(hoverDefaultIndex);
  };
  // 紀錄目前 hover 的項目
  const setHover = (index) => {
    // over min
    if (index < 0) {
      leave();
    }
    // over max
    if (index >= length(items.value)) {
      const lastIndex = length(items.value) - 1;
      index = lastIndex;
      emitCurrentItem(lastIndex);
    }
    hover.value = index;
  };
  // 是否被滑過：灰底色
  const isHoverClass = (index) => {
    return { hover: hover.value === index };
  };
  // ? 還在看用不用得到
  const refName = (index) => {
    return `recommend-${index}`;
  };
  // 選擇關鍵字
  const select = (keyword) => {
    emit('select', join('', values(keyword)));
  };
  // 滑過
  const over = (index) => {
    emitCurrentItem(index);
    hover.value = index;
  };

  watch(
    () => value.value,
    (newValue) => {
      setHover(newValue);
    },
    { immediate: true },
  );

  return {
    renderCount,
    keywordTypeEnum,
    hover,
    items,
    leave,
    isHoverClass,
    refName,
    select,
    over,
  };
};

export default {
  name: 'SearchBarAutoComplete',
  props,
  emits: ['select', 'overItem'],
  setup,
};
</script>

<style lang="scss" scoped>
@import '@/assets/mixins/search/auto-complete.scss';
</style>
