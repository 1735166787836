import LocalRecordsService from '@/services/search/local-records-service';
import LocalStorageServices from '@/services/local-storage-services';

function createServiceInstance() {
  try {
    return new LocalRecordsService(new LocalStorageServices(Storage, localStorage));
  } catch (error) {
    console.log(error.message);
    return null;
  }
}

export function useSearchLocalRecord({ query }) {
  const localRecordService = createServiceInstance();

  const getLocalRecord = () => {
    const record = localRecordService?.get() || [];
    return record;
  };
  // 寫入搜尋紀錄 (Local)
  const setLocalRecord = () => {
    if (!localRecordService) return;
    localRecordService.save(query.value);
  };

  return {
    getLocalRecord,
    setLocalRecord,
  };
}
