<template>
  <div ref="lottieLoadingRef" class="lottie-loading"></div>
</template>

<script setup>
import { computed, ref, toRefs, inject, watch, nextTick, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import lottie from 'lottie-web';
import shoppingcartJsonData from '@/static/lottie/shoppingcart.json';
import shoppingcartJsonDataM from '@/static/lottie/shoppingcart-m.json';
import { useWindowResizeEvent } from '@/composables/utility/window-resize-event';

// 非白色shop-cart-icon
const filterShopIcon = ['exhibition', 'market'];

const props = defineProps({
  count: { type: Number, default: 0 },
});

const { count } = toRefs(props);

const $screen = inject('$screen');
const route = useRoute();

/** template ref */
const lottieLoadingRef = ref(null);

/** data */
const anim = ref(null);
// pc/mobile改變 true = mobile
const isSwitchMobile = ref(null);

/** computed */
const shoppingcartIcon = computed(() => {
  const filterLayout = filterShopIcon.includes(route.meta?.layout);
  if (!filterLayout && isSwitchMobile.value) return shoppingcartJsonDataM;
  return shoppingcartJsonData;
});

/** methods */
const lottieInit = () => {
  anim.value = lottie.loadAnimation({
    container: lottieLoadingRef.value,
    renderer: 'svg',
    loop: false,
    autoplay: false,
    name: 'shoppingcart',
    animationData: shoppingcartIcon.value,
  });
};

const play = (count, hasAnimation = true) => {
  anim.value.renderer.elements[0]?.updateDocumentData({ t: `${count}` });
  if (count < 1) {
    // 如果是空車，不需顯示數量
    anim.value.goToAndStop(0);
    return;
  }
  if (!hasAnimation) {
    // 切換頁，layout不同，動畫不需重跑
    anim.value.goToAndStop(anim.value.firstFrame + anim.value.totalFrames - 1, true);
    return;
  }
  anim.value.goToAndPlay(0);
};

const resizeEventHandler = (newValue, oldValue) => {
  // 初始值裝置寬度預設為0，記錄真正第一次寬度
  if (newValue !== 0 && oldValue === 0) isSwitchMobile.value = $screen.isMobileSize;
  if (isSwitchMobile.value !== $screen.isMobileSize) {
    isSwitchMobile.value = $screen.isMobileSize;
    if (!anim.value) return;
    // pc/mb shopping-cart icon
    nextTick(() => {
      lottie.destroy('shoppingcart');
      lottieInit();
      play(count.value, false);
    });
  }
};

/** composable */
useWindowResizeEvent(resizeEventHandler);

/** watch */
watch(count, (newValue) => play(newValue));

onMounted(() => {
  lottieInit();
  // 切換頁，layout不同，動畫不需重跑
  play(count.value, false);
});
</script>

<script>
export default { name: 'ShoppingCart' };
</script>

<style scoped>
.lottie-loading {
  font-family: 'Noto Serif TC';
}
</style>
