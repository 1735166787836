<template>
  <div class="tabbar-and-category">
    <!-- Tab Bar -->
    <div ref="tabbarRef" class="tabbar !tw-hidden" :style="`grid-template-columns: repeat(${buttons.length}, auto);`">
      <button v-for="btn in buttons" :key="btn.name" :title="btn.name" :class="{ active: btn.isActive }" class="tab" type="button" @click="handleButtonClick(btn)">
        <div ref="iconRefs[btn.name]" class="base-icon">
          <shopping-cart v-if="btn.name === '購物車'" class="shopping-cart-lottie" :count="cartQuantity"></shopping-cart>
          <img :src="btn.icon" :alt="btn.name" class="icon" />
          <div class="lottie-box"></div>
        </div>
        <div class="title">{{ btn.name }}</div>
      </button>
    </div>

    <Menu :open-menu="activeModal" />
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onBeforeUnmount, onUnmounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import lottie from 'lottie-web';

import { storeToRefs } from 'pinia';
import { useCartItemsStore } from '@/stores/cart-items';
import { useLoginStore } from '@/stores/login';

import ShoppingCart from '@/components/layout/elements/shopping-cart.vue';
import Menu from '@/components/layout/menu-bar-with-mobile2024.vue';

import homeIcon from '@/static/images/icons/tabbar/Home.svg';
import categoriesIcon from '@/static/images/icons/tabbar/Categories.svg';
import saveIcon from '@/static/images/icons/tabbar/Save.svg';
import shoppingIcon from '@/static/images/icons/tabbar/Shopping.svg';
import myIcon from '@/static/images/icons/tabbar/My.svg';

import homeJsonData from '@/static/lottie/tabbar/Home.json';
import categoriesJsonData from '@/static/lottie/tabbar/Categories.json';
import saveJsonData from '@/static/lottie/tabbar/Save.json';
import shoppingJsonData from '@/static/lottie/tabbar/Shopping.json';
import myJsonData from '@/static/lottie/tabbar/My.json';

// store
const cartItemsStore = useCartItemsStore();
const { initCartQuantity, clearQuantity } = cartItemsStore;
const { getTotalOfCartItems, getQuantity } = storeToRefs(cartItemsStore);
const loginStore = useLoginStore();

const tabbarRef = ref(null);
const router = useRouter();
const route = useRoute();

// 控制顯示哪個 Modal 的狀態
const activeModal = ref(false);
const modalMessage = ref('');

// Lottie animat group
const lottieInstances = ref({});
// 按鈕存放區
const iconRefs = ref({});

/** @const {ref[{}]} buttons 按鈕資訊 */
const buttons = ref([
  // { name: '限時活動 (測試)', isActive: false, icon: categoriesIcon, lottieFile: homeJsonData, type: 'method', route: null, checkRoute: null, methodName: 'showLimitedTimeEvent' },
  { name: '首頁', isActive: false, icon: homeIcon, lottieFile: homeJsonData, type: 'route', route: '/?type=newindex', checkRoute: ['/'] },
  { name: '分類', isActive: false, icon: categoriesIcon, lottieFile: categoriesJsonData, type: 'method', route: null, checkRoute: null, methodName: 'showCategoryContent' },
  { name: '我的收藏', isActive: false, icon: saveIcon, lottieFile: saveJsonData, type: 'route', route: '/member/wish', checkRoute: ['/member/wish'] },
  { name: '購物車', isActive: false, icon: shoppingIcon, lottieFile: shoppingJsonData, type: 'route', route: '/cart/step1', checkRoute: ['/cart/step1', '/cart/step2', '/cart/step3'] },
  { name: '會員中心', isActive: false, icon: myIcon, lottieFile: myJsonData, type: 'route', route: '/member', checkRoute: ['/member'] },
]);

// set Lottie animate
const loadLottieAnimation = (buttonName, lottieFile) => {
  if (iconRefs.value[buttonName]) {
    const instance = lottie.loadAnimation({ container: iconRefs.value[buttonName], renderer: 'svg', loop: false, autoplay: true, animationData: lottieFile });
    lottieInstances.value[buttonName] = instance;
  }
};

// destroy Lottie animate
const unloadLottieAnimation = (buttonName) => {
  const instance = lottieInstances.value[buttonName];
  if (instance) {
    instance.destroy();
    delete lottieInstances.value[buttonName];
  }
};

// 更新按鈕狀態 (with route path)
const updateButtonState = (routePath) => {
  buttons.value.forEach((btn) => {
    btn.isActive = btn.checkRoute ? btn.checkRoute.includes(routePath) : false;
    // 根據按鈕狀態加載或卸載 Lottie 動畫
    if (btn.isActive) lottieInstances.value[btn.name]?.goToAndPlay(0);
  });
};

// 設置 method 按鈕的 isActive 狀態
const setActiveMethodButton = (buttonName) => {
  buttons.value.forEach((btn) => {
    if (btn.type === 'method') btn.isActive = btn.name === buttonName;
  });
};

// 顯示分類內容的函式
const showCategoryContent = () => {
  modalMessage.value = '這裡顯示分類內容';
  activeModal.value = true;
  setActiveMethodButton('分類');
};

// 顯示限時活動的函式 (測試)
const showLimitedTimeEvent = () => {
  modalMessage.value = '這裡顯示限時活動內容';
  activeModal.value = true;
  setActiveMethodButton('限時活動');
};

// method group
const methods = { showCategoryContent, showLimitedTimeEvent };

// 取消所有 methods 呼叫 (關閉 Modal method)
const closeModal = () => {
  activeModal.value = false;
  buttons.value.forEach((btn) => {
    if (btn.type === 'method') btn.isActive = false;
  });
};

const callMethod = (methodName) => (methods[methodName] ? methods[methodName]() : false);

// click 更新按鈕狀態
const handleButtonClick = (button) => {
  activeModal.value = false;
  if (button.type === 'route' && button.route) {
    buttons.value.forEach((btn) => (btn.isActive = btn.checkRoute !== null ? btn.checkRoute.includes(route.path) : false));
    // 跳頁按鈕：轉跳
    router.push(button.route);
    lottieInstances.value[button.name].goToAndPlay(0);
  } else if (button.type === 'method' && button.methodName) {
    buttons.value.forEach((btn) => (btn.isActive = btn.checkRoute !== null ? btn.checkRoute.includes(route.path) : false));
    // 事件按鈕：呼叫事件
    callMethod(button.methodName);
    lottieInstances.value[button.name].goToAndPlay(0);
  }
};

const totalOfCartItems = computed(() => getTotalOfCartItems.value);
const storeCartQuantity = computed(() => getQuantity.value);
const isLogin = computed(() => loginStore.loggedIn);

// computed
const cartQuantity = computed(() => {
  // 未登入一律拿 local cart
  return !isLogin.value ? totalOfCartItems.value : storeCartQuantity.value;
});

onMounted(async () => {
  // init
  initCartQuantity();
  await buttons.value.forEach((btn, index) => {
    iconRefs.value[btn.name] = tabbarRef.value.querySelectorAll(`.base-icon`)[index]?.querySelector('.lottie-box');
    loadLottieAnimation(btn.name, btn.lottieFile);
  });
  await updateButtonState(route.path);
  tabbarRef.value?.classList.remove('!tw-hidden');

  watch(
    route,
    (nv, ov) => {
      if (nv !== ov) {
        updateButtonState(nv.path);
        closeModal();
      }
    },
    { immediate: true },
  );
});

// destroy Lottie animate
onBeforeUnmount(() => Object.keys(lottieInstances.value).forEach((buttonName) => unloadLottieAnimation(buttonName)));
onUnmounted(clearQuantity);
</script>

<style scoped>
.tabbar {
  @apply tw-w-full tw-h-[72px] tw-grid tw-gap-6 tw-justify-center tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-z-10;
  background-color: theme('colors.white');
  box-shadow: 0px 1px 0px 0px #ededed inset;
}

.tab {
  @apply tw-w-12 tw-h-[42px] tw-p-0 tw-mt-[6px] tw-flex tw-flex-col tw-items-center tw-no-underline tw-border-none;
}

.icon,
.base-icon {
  @apply tw-w-6 tw-h-6 tw-mb-1 tw-mx-auto tw-text-center tw-relative;
}
.shopping-cart-lottie {
  @apply tw-w-11 tw-h-11 tw-absolute tw-top-[-14px] tw-left-[-6px];
}

.lottie-box,
.lottie-box :deep(svg) {
  @apply tw-w-6 tw-h-6 tw-mb-1 tw-mx-auto tw-text-center;
}

.title {
  @apply tw-text-[10px] tw-leading-[14px] tw-no-underline;
  color: theme('colors.gray-300');
}

.tab {
  .icon {
    @apply tw-block;
  }
  .lottie-box {
    @apply tw-hidden;
  }
}

.tab.active {
  .icon {
    @apply tw-hidden;
  }
  .lottie-box {
    @apply tw-block;
  }
}

.tab:hover,
.tab.active {
  .title {
    color: theme('colors.gold-500');
  }
}

.modal-overlay {
  @apply tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center tw-bg-black/50;
  z-index: 1000; /* 確保 Modal 在最上層 */
}

.modal-content {
  @apply tw-bg-white tw-p-4 tw-rounded-lg tw-shadow-lg tw-w-72 tw-text-center;
}

.close-button {
  @apply tw-mt-4 tw-px-4 tw-py-2 tw-bg-blue-500 tw-text-white tw-rounded-md;
}
</style>
