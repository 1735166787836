import { searchVersion } from '@/constant/search/search';

import autoCompleteService from '@/services/search/auto-complete-service';
import googleAutoCompleteService from '@/services/search/google-auto-complete-service';

export default class SearchRecommendFactory {
  constructor(version) {
    switch (version) {
      case searchVersion.v2:
        return autoCompleteService;
      case searchVersion.v3:
        return googleAutoCompleteService;
      default:
        throw Error(`recommend version error. please check the version ${version} is correct.`);
    }
  }
}
