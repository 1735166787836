import { searchSortTypeEnum } from '@/constant/search/sort-type';

export const searchVersion = {
  v2: 2,
  v3: 3,
};

export const expEnum = {
  a: 'a',
  b: 'b',
  origin: 'o',
};

export const expSearchVersionEnum = {
  [expEnum.a]: searchVersion.v2,
  [expEnum.b]: searchVersion.v3,
  [expEnum.origin]: searchVersion.v2,
};

export const productStatus = [
  {
    label: '商品數量可購買',
    value: 'add_to_shopping_cart',
    count: 0,
  },
];

export const timeCondition = [
  {
    label: '近三個月出版',
    value: 3,
    sort: 3,
  },
  {
    label: '近六個月出版',
    value: 6,
    sort: 5,
  },
  {
    label: '不限時間',
    value: 0,
    sort: 1,
  },
];

export const historyCount = 5;

export const directSearchColumnEnum = {
  author: 'author',
  originAuthor: 'originAuthor',
  compiler: 'compiler',
  translator: 'translator',
  painter: 'painter',
  brandName: 'brandName',
  publisher: 'manufacturer',
};

/**
 * 頁面預設排序
 * @scope 使用到搜尋功能的頁面 (category / search)
 * @type {{search, category}}
 */
export const pageDefaultSort = {
  category: searchSortTypeEnum.publishDate,
  search: searchSortTypeEnum.score,
};
