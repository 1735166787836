<template>
  <div class="products">
    <card v-for="product in products" :key="product.pid" :product="product" />
  </div>
</template>

<script setup>
import { toRefs } from 'vue';
import Card from '@/components/product/card-index2024.vue';

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  products: {
    type: Array,
    required: true,
  },
});

const { products } = toRefs(props);
</script>

<style scoped>
.products {
  display: flex;
  gap: 8px;

  :deep(.product-card) {
    width: 140px;
    height: auto;
    flex-shrink: 0;
  }
  :deep(.card-image) {
    width: 140px;
    height: 140px;
  }
  :deep(.product-card-title) {
    @apply tw-text-[13px];
  }
  :deep(.product-card-description) {
    @apply tw-text-[14px];
  }
  :deep(.origin-dollar) {
    @apply tw-text-[12px];
  }
}
</style>
