import { ref } from 'vue';
import { includes } from 'ramda';
import { removeCartInfo } from '@/helper/cart/cart-default-data';
import loginPath from '@/constant/login-path';
import { bridgewellTrack } from '@/helper/tracking/bridgewell';
import { useCartItemsStore } from '@/stores/cart-items';
import { useLoginStore } from '@/stores/login';

export function useLogout({ auths, route, router }) {
  const prePage = ref('');
  const logoutSuccessMessage = ref('');

  // store
  const cartItemsStore = useCartItemsStore();
  const loginStore = useLoginStore();

  const clearLogoutMessage = () => {
    logoutSuccessMessage.value = '';
  };

  const reloadPage = () => {
    // refresh
    // logout=refresh 只需要加在登入的頁面及特定頁面(search、category-L3)
    if (includes(route.path, loginPath) || ['search', 'category-3-id'].includes(route.name)) {
      router.push({ path: `${route.path}`, query: { ...route.query, logout: 'refresh' } });
      return;
    }
    // 跳頁 or reload
    if (prePage.value !== route.fullPath) {
      prePage.value = '';
      router.push(route.fullPath);
    } else window.location.reload();
  };
  const closeModal = () => {
    clearLogoutMessage();
    reloadPage();
  };

  const logout = async () => {
    prePage.value = route.fullPath;
    await removeCartInfo(loginStore.user);
    await auths
      .logout()
      .then(() => {
        // clear local cart
        cartItemsStore.clearLocalCartAndStore();

        // show message
        logoutSuccessMessage.value = '登出成功。';
        // 電子書強制登出需要按確定
        // setTimeout(() => {
        //   clearLogoutMessage();
        //   reloadPage();
        // }, 3000);

        // bridgewell
        bridgewellTrack.sendInitUserInfo({ isLogin: false });
      })
      .catch((error) => {
        const errorMessage = typeof error === 'object' ? JSON.stringify(error) : error;
        throw new Error(`login fail, error is => ${errorMessage}`);
      });
  };

  return {
    logout,
    logoutSuccessMessage,
    clearLogoutMessage,
    closeModal,
  };
}
