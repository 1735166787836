/** @const {string} activityColor 行銷活動顏色 */
export const activityColor = '#E55970';

/** @const {object[]} categoryColors 館別顏色 */
export const categoryColors = [
  { color: '#40462E', hover: '#F5F6EF' }, // 中文書
  { color: '#40462E', hover: '#F5F6EF' }, // 電子書
  { color: '#545E3A', hover: '#F5F6EF' }, // 外文書
  { color: '#636F41', hover: '#F5F6EF' }, // 日文書
  { color: '#7F8C56', hover: '#F5F6EF' }, // 雜誌
  { color: '#2CBB43', hover: '#F1FCF2' }, // 童書
  { color: '#F39800', hover: '#FFF3E0' }, // 親子用品
  { color: '#FBC02D', hover: '#FFFDE7' }, // 文具潮藝
  { color: '#E55970', hover: '#FEF2F3' }, // 服飾鞋包
  { color: '#921F3E', hover: '#FEF2F3' }, // 美妝保養
  { color: '#0A655F', hover: '#E0F2F1' }, // 休閒戶外
  { color: '#054E87', hover: '#E3F2FD' }, // 生活家居
  { color: '#E27700', hover: '#FFF3E0' }, // 食品保健
  { color: '#B0B0B0', hover: '#F4F4F4' }, // 3C
  { color: '#0A4170', hover: '#E3F2FD' }, // 家電
  { color: '#601986', hover: '#EDE7F6' }, // 影音
];
