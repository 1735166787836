/**
 * searchRoute
 *  - 會用到搜尋功能的 `route`
 * @type {{search: string, category: string}}
 */
export const searchRoute = {
  search: 'search',
  // todo 寫到分類再加
  category: 'category-3-id',
  brandCategory: 'brand-id-category',
};

export const categorySearchRoutes = [searchRoute.category, searchRoute.brandCategory];
