<template>
  <div class="product-card">
    <router-link-usage v-if="hasProduct" :link="productLink" :title="productAlt" class="card-link">
      <div class="tw-grid tw-grid-rows-[1fr_auto]">
        <!-- 左右區塊 -->
        <div class="tw-grid tw-grid-cols-[minmax(186px,53%)_8px_minmax(164px,47%)] tw-gap-0">
          <!-- 左側區塊 -->
          <div class="card-image-box">
            <product-lazy-image
              :src="productImage"
              :is-adult="isRestricted"
              :alt="productAlt"
              :is-pre-order="isPreOrder"
              :error-img="productFail628x418"
              :restricted-img="productRestricted628x418"
              class="card-image"
            />
            <div v-if="discountPriceOnly" class="card-sale">
              <span class="discount bold">{{ discountPriceOnly }}</span>
              <span class="zhe">折</span>
            </div>
          </div>

          <!-- 間隔 -->
          <div></div>

          <!-- 右側區塊 -->
          <div class="card-text-box">
            <div v-if="!isEmptyValue(product.message1)" class="gold-message">{{ product.message1 }}</div>
            <div v-if="!isEmptyValue(product.message2)" class="gold-message">{{ product.message2 }}</div>
            <div v-if="!isEmptyValue(productTitle)" class="product-title">{{ productTitle }}</div>
            <div class="dollar-box">
              <span class="sale-dollar">{{ priceFormat(getPrices[productId]) }}</span>
              <span v-if="discountPriceOnly" class="origin-dollar">{{ priceFormat(getPrePrices[productId]) }}</span>
            </div>
          </div>
        </div>

        <!-- 下方 w-full 區塊 -->
        <div class="product-description-box">
          <div class="product-description">{{ productSubTitle }}</div>
        </div>
      </div>
    </router-link-usage>
  </div>
</template>

<script setup>
import { ref, toRefs, computed, watch, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { usePriceAndImageStore } from '@/stores/price-and-image.js';
import RouterLinkUsage from '@/components/utility/router-link-usage.vue';
import ProductLazyImage from '@/components/utility/product/image/product-lazy-image.vue';
import productPrice from '@/composables/product/product-price';
import { isEmptyValue } from '@/helper/data-process';
import priceFormat from '@/helper/filter/price-format';
import { productFail628x418, productRestricted628x418 } from '@/constant/images-path';

const props = defineProps({
  product: { type: Object, default: () => ({}) },
});
const { product } = toRefs(props);
const priceAndImageStore = usePriceAndImageStore();
const { getPrices, getPrePrices, getImages, getType } = storeToRefs(priceAndImageStore);

const productPriceRef = ref(null);

/** @const {string} productId 商品 id */
const productId = computed(() => product.value?.id || product.value?.guid || product.value?.product_guid || product.value?.esliteSN || '');
/** @const {string} hasProduct 有商品？ */
const hasProduct = computed(() => !isEmptyValue(productId.value));
/** @const {string} productLink 商品連結 */
const productLink = computed(() => (hasProduct.value === false ? '' : product.value.link || product.value.url || `/product/${productId.value}`));
/** @const {string} productTitle 標題 */
const productTitle = computed(() => product.value?.title || '');
/** @const {string} productAlt Alt提示 */
const productAlt = computed(() => product.value?.alt || productTitle.value);
/** @const {string} productSubTitle 副標題 */
const productSubTitle = computed(() => product.value?.subTitle || '');
/** @const {string} productImageType 商品圖片類型 */
const productImageType = computed(() => {
  if (getType.value[productId.value]?.restricted === true) return 'restricted'; // 是18禁商品
  if (getType.value[productId.value]?.restricted === false) return 'default'; // 一般圖片
  return 'loading'; // loading 圖
});
/** @const {boolean} isRestricted 是否為18禁商品 */
const isRestricted = computed(() => productImageType.value === 'restricted' || false);
/** @const {boolean} isPreOrder 是否為預購商品 */
const isPreOrder = computed(() => getType.value[productId.value]?.preOrder || false);
/** @const {boolean} isEbook 是否為電子書 */
const isEbook = computed(() => getType.value[productId.value]?.ebook || false);
/** @const {string} productImage 商品圖 */
const productImage = computed(() => product.value?.image || getImages.value[productId.value]?.medium || '');
const discountPriceOnly = computed(() => productPriceRef.value?.discountPriceOnly || undefined);

onMounted(() => {
  watch(
    productId,
    () => {
      if (!isEmptyValue(productId.value)) productPriceRef.value = productPrice({ id: productId.value, mode: 'price-and-image' });
    },
    { immediate: true },
  );
});
</script>

<style scoped>
.product-card {
  @apply tw-mb-4 tw-bg-white tw-overflow-hidden;
  /* outline: 1px solid green; */
}

.card-link {
  @apply tw-inline;
}

.card-image-box {
  @apply tw-relative tw-rounded-lg;
  :deep(.product-image) {
    @apply tw-pt-[67%];
  }
}

.card-image {
  @apply tw-w-full tw-object-contain tw-rounded-lg;
  /* outline: 1px solid yellow; */
  @apply tw-pt-[67%];
}

.card-sale {
  @apply tw-absolute tw-top-0 tw-left-0 tw-text-sm tw-px-2 tw-py-1 tw-rounded-tl-lg;
  color: theme('colors.white');
  background: theme('colors.pink-500');
}

.card-text-box {
  @apply tw-w-full;
}

.gold-message {
  @apply tw-w-full tw-text-[13px] tw-leading-[18px] tw-font-medium tw-text-nowrap tw-overflow-hidden tw-text-ellipsis tw-break-all;
  color: theme('colors.gold-500');
}

.product-title {
  @apply tw-w-full tw-h-10 tw-mt-1 tw-text-sm tw-font-medium tw-line-clamp-2 tw-overflow-hidden tw-text-ellipsis tw-break-all;
  color: theme('colors.gray-800');
}

.dollar-box {
  @apply tw-mt-3 tw-flex tw-items-center;
}

.sale-dollar {
  @apply tw-text-[16px] tw-leading-[22px] tw-not-italic tw-font-bold;
  color: theme('colors.red-600');
  &::before {
    content: '$';
  }
}
.origin-dollar {
  @apply tw-text-[12px] tw-leading-[21px] tw-not-italic tw-font-normal tw-line-through tw-ml-1;
  color: theme('colors.gray-300');
  &::before {
    content: '$';
  }
}

.product-description-box {
  @apply tw-w-full tw-mt-[10px] tw-p-3;
  color: theme('colors.gray-400');
  background-color: theme('colors.gray-50');
}
.product-description {
  @apply tw-w-full tw-h-9 tw-text-[13px] tw-leading-[18px] tw-font-normal tw-overflow-hidden tw-line-clamp-2 tw-break-all;
  color: theme('colors.gray-400');
  background-color: theme('colors.gray-50');
}

a:active,
a:hover,
a:visited {
  @apply tw-no-underline;

  .product-card-title {
    color: theme('colors.gold-500');
  }
  .sale-dollar {
    color: theme('colors.red-600');
  }
  .origin-dollar {
    @apply tw-line-through;
    color: theme('colors.gray-300');
  }
  .product-card-description {
    color: theme('colors.gray-500');
  }
}
</style>
