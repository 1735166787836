<template>
  <BannerBoxMobile :show-banner="hasItems" :title="title">
    <template #body>
      <div v-if="hasItems" class="box tw-w-full">
        <div v-for="(row, index) in items" :key="index" class="tw-w-full tw-space-y-4">
          <RouterLinkUsage :link="row.banner.link" :title="row.banner.alt"><img :src="row.banner.image" :alt="row.banner.alt" class="full-banner" /></RouterLinkUsage>
          <div class="tw-w-full tw-grid tw-grid-cols-3 tw-gap-4 tw-pb-4">
            <card v-for="(product, pindex) in row.products" :key="pindex" :product="product" :mode-auto="true" />
          </div>
        </div>
      </div>
    </template>
  </BannerBoxMobile>
</template>

<script setup>
import { toRefs, computed, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useHomeAd2024Store } from '@/stores/home-ad-2024';
import { usePriceAndImageStore } from '@/stores/price-and-image';
import BannerBoxMobile from '@/components/layout/elements/mobile/banner-box-mobile-2024index.vue';
import Card from '@/components/product/card-index2024.vue';
import RouterLinkUsage from '@/components/utility/router-link-usage.vue';

const homeAdStore = useHomeAd2024Store();
const { getStrikingBrands } = storeToRefs(homeAdStore);
const priceStore = usePriceAndImageStore();
const { fetchPrice } = priceStore;
const items = computed(() => getStrikingBrands.value?.items || []);
const hasItems = computed(() => items.value?.length > 0 || false);

const props = defineProps({ title: { type: String, default: '' } });
const { title } = toRefs(props);

watch(
  items,
  () => {
    const ids = items.value?.flatMap((item) => item.products?.map((product) => product.esliteSN)) || [];
    if (ids.length > 0) fetchPrice({ productIds: ids });
  },
  { deep: true },
);
</script>

<style scoped>
.full-banner {
  @apply tw-w-full tw-rounded-lg;
}
</style>
