import { useRoute } from 'vue-router';
import { computed, inject, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { ClientJS } from 'clientjs';

// store
import { useRetailSearchLogStore } from '@/stores/retail-search-log';

// service
import FingerprintService from '@/services/log/fingerprint-service';
import RetailEventService from '@/services/log/retail-event-service';

// helper
import { getGoogleAnalyticsClientId } from '@/helper/gtm';

// api
import postLog from '@/api/log/search-log-events';

const setToken = (token, setToStore) => (value) => {
  token.value = value;
  // set to store : 離開 search page 使用
  // > attribution token 使用，建議不要 cache，以免造成資料錯誤 / 流程混亂
  // > 所以使用 store > localstorage
  // console.log(setToStore);
  setToStore(token);
};

const getToken = (token) => computed(() => token.value);

export function useRetailLogEvent() {
  /**
   * vue instance
   */
  const auths = inject('$auths');

  /**
   * store
   */
  const retailStore = useRetailSearchLogStore();
  const { setAttributionToken } = retailStore;
  const { getAttributionToken } = storeToRefs(retailStore);

  /**
   * user data
   */
  const fingerprintService = new FingerprintService(new ClientJS());
  const clientId = getGoogleAnalyticsClientId() || fingerprintService.get() || '';
  const user = auths?.user()?.uuid || '';

  /**
   * vue data
   */
  const attributionToken = ref('');

  /**
   * A/B Testing Logging
   * */
  const retailLogService = new RetailEventService(postLog, clientId, auths.getToken());

  /**
   * export methods
   */
  const setSearchToken = setToken(attributionToken, setAttributionToken);

  /**
   * computed
   */
  const getSearchToken = getToken(attributionToken);
  const getTokenFromStore = computed(() => getAttributionToken.value);

  return {
    retailLogService,
    setSearchToken,
    searchToken: getSearchToken,
    getTokenFromStore,
    traceUser: clientId,
    uuid: user,
  };
}
