import axios from 'axios';
import { includes } from 'ramda';
import { storeToRefs } from 'pinia';
import { isEmptyValue } from '@/helper/data-process';
import { getMember, getMemberDetail } from '@/api/member/member-info';
import { defKey } from '@/constant/auth';
import { cartPath } from '@/constant/login-path';
import AuthStorageService from '@/services/AuthStorageService';
import { useCartItemsStore } from '@/stores/cart-items';
import { useLoginStore } from '@/stores/login';

const delay = (ms, value) => new Promise((res) => setTimeout(() => res(value), ms));

const AuthService = () => {
  const AuthStorage = new AuthStorageService();

  // store
  const loginStore = useLoginStore();
  const cartItemsStore = useCartItemsStore();
  const { changeLogin, setUser, setPrivacyUser } = loginStore;
  const { user, privacyUser } = storeToRefs(loginStore);

  /**
   * 判斷 token 規則是否正確
   * @param {string} token token
   * @return {boolean}
   */
  const checkToken = (token = '') => {
    const useToken = isEmptyValue(token) ? AuthStorage.getToken() : token;
    // token 為 false 或為空時: 不是合法 token
    if (defKey.false === useToken || isEmptyValue(useToken)) return false;

    // 開頭字 `Bearer ` 且長度在兩百多時: 基本正確
    return useToken.substr(0, 7) === defKey.bearer && useToken.length > 200 && useToken.length < 350 && /^Bearer\s[a-zA-Z0-9-_]+\.[a-zA-Z0-9-_]+\.[a-zA-Z0-9-_]+$/.test(useToken);
  };

  const clearAuthState = async () => {
    AuthStorage.setLogout();
    await changeLogin(false);
    await setUser(null);
    await setPrivacyUser(null);
  };

  const clearAuthStateAndInitCartQuantity = async () => {
    await clearAuthState();
    await cartItemsStore.initCartQuantity();
  };

  const apiGetMemberAndResetAuthState = async (token, priv = false) => {
    if (!checkToken(token)) {
      await clearAuthStateAndInitCartQuantity();
      return Promise.reject(new Error(false));
    }
    // console.log(`[plugins] auths: setPrivacyUser: ${priv}`);
    const controller = new AbortController();
    const callAPI = priv ? getMemberDetail : getMember;
    let res = null;
    try {
      res = await callAPI(token, { signal: controller.signal });
      if (res.status === 200 && typeof res.data?.id === 'number') {
        // console.log({ token, rd: res.data });
        AuthStorage.setIsLogin(true);
        AuthStorage.setToken(token);
        delete res.data.passport_no;
        await setUser(res.data);
        if (priv) await setPrivacyUser(res.data);
        await changeLogin(true);
      } else {
        await clearAuthStateAndInitCartQuantity();
        res = null;
      }
    } catch (error) {
      await clearAuthStateAndInitCartQuantity();
      return Promise.reject(error);
    }
    controller.abort();
    return delay(10, res);
  };
  const apiLogin = ({ data, needPrivacyUser }) => {
    return axios({
      method: 'post',
      url: `${import.meta.env.VITE_API}${import.meta.env.VITE_API_LOGIN}`, // ?fail=1
      data,
    })
      .then(async (res) => {
        if (res?.data?.token) {
          AuthStorage.setToken(res.data.token);
          const check = checkToken();
          // 取得會員資訊
          if (check) {
            const token = AuthStorage.getToken();
            // 避免 login page checkMemberStatus 沒有 member data
            await apiGetMemberAndResetAuthState(token, needPrivacyUser);
          }
        }

        return res;
      })
      .catch((e) => {
        AuthStorage.setToken(defKey.false);
        throw e;
      });
  };
  /**
   * 登出
   * @return {Promise<AxiosResponse<any>>}
   */
  const apiLogout = async () => {
    clearAuthState();
    return axios.delete(`${import.meta.env.VITE_API}${import.meta.env.VITE_API_LOGOUT}`);
  };

  const verifyToken = (priv = false) => apiGetMemberAndResetAuthState(AuthStorage.getToken(), priv);

  return {
    loginWith(data) {
      return apiLogin({ data: data.data, needPrivacyUser: true });
    },
    logout: apiLogout,
    setToken: (token) => AuthStorage.setToken(token),
    getToken: () => AuthStorage.getToken(),
    checkToken: (token) => checkToken(token),
    verifyTokenAndInitUser: verifyToken,
    user: () => user.value,
    privacyUser: () => privacyUser.value,
    isLogin: () => AuthStorage.getIsLogin(),
  };
};

export default async ({ pluginContext, app }) => {
  app.config.globalProperties.$auths = AuthService();
  pluginContext.$auths = app.config.globalProperties.$auths;

  const priv = false;
  // 特定頁面不要一進入就登入
  if (includes(window.location.pathname, cartPath)) return false;

  // 進入畫面時呼叫 member api
  await app.config.globalProperties.$auths.verifyTokenAndInitUser(priv).catch(() => {});

  return true;
};

/**
 * vue test-utils 不會等 async plugin，避免 mount component 使用到錯誤 auths
 * 因此不執行 verifyTokenAndInitUser，直接 mock login store
 */
export const authsPluginForTest = ({ pluginContext, app }) => {
  app.config.globalProperties.$auths = AuthService();
  pluginContext.$auths = app.config.globalProperties.$auths;
};
