/**
 * 範圍亂數
 * @param {number} max 最大值
 * @param {number} min 最小值
 * @return {number} random number 亂數
 */
export const random = (max = 10, min = 0) => Math.floor(Math.random() * (max - min + 1)) + min;

/**
 * 隨機產生順序陣列
 * @param {number} start 起始值
 * @param {number} stop 結束值
 * @param {number} step 遞增值
 * @returns {number[]} 產生的陣列組
 * @example rangeArray(1, 10, 2) => [1, 3, 5, 7, 9]
 * @example rangeArray('A'.charCodeAt(0), 'Z'.charCodeAt(0), 1)
 *            .map(x => String.fromCharCode(x));
 * // => ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
 */
export const rangeArray = (start = 0, stop = 10, step = 1) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

/**
 * 隨機長度陣列
 * @param {number} length 陣列長度
 * @param {number} max 陣列內亂數數值最大值
 * @return {number[]} 隨機陣列
 */
export const randomArray = (length = 10, max = 10) => Array.from({ length }, () => random(max));

/**
 * 建立陣列資料（用於機率）
 * ex. generateBaseArray('a', 3) -> ['a','a','a']
 * 百分之20% = 100個陣列裡面有 20 個指定資料
 * @param content
 * @param length
 * @returns {*[]}
 */
export const generateBaseArray = (content, length) => {
  const array = [];
  let step;
  for (step = 0; step < length; step += 1) {
    array.push(content);
  }
  return array;
};
