import { LocalStorageKey } from '@/constant/local-storage';
import { BrowserNotSupportException, browserNotSupportType } from '@/exception/browser-not-support-exception';

export default class BaseLocalStorage {
  constructor(localStorageService, keyName) {
    /** localStorageService */
    this.localStorageService = localStorageService;
    this.key = keyName;
    this.check();
  }

  isSupport() {
    return this.localStorageService.isSupport();
  }

  check() {
    if (!this.isSupport()) throw new BrowserNotSupportException(browserNotSupportType.localStorage);
  }

  get(defaultResponse = []) {
    if (!this.isSupport()) return defaultResponse;
    return this.localStorageService.get(this.key, defaultResponse);
  }
  delete() {
    this.localStorageService.remove(this.key);
  }
}
