<template>
  <template v-if="hasItems">
    <thread-element v-for="(row, index) in items" :key="index" :name="row.name" :items="row.items" :cid="index" />
  </template>
</template>

<script setup>
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useHomeAd2024Store } from '@/stores/home-ad-2024';
import ThreadElement from '@/components/layout/elements/thread-element.vue';

const homeAdStore = useHomeAd2024Store();
const { getNewThreadGroup } = storeToRefs(homeAdStore);

const items = computed(() => getNewThreadGroup?.value?.items || []);
const hasItems = computed(() => items.value?.length > 0);
</script>
