import { useRoute } from 'vue-router';
import { isEmptyValue } from '@/helper/data-process';

function getSearchBarInitialValue(routeQuery) {
  if (!isEmptyValue(routeQuery.query) || !isEmptyValue(routeQuery.keyword)) {
    return routeQuery.query || routeQuery.keyword || '';
  }
  return '';
}

export function useSearchBarInit({ query }) {
  const route = useRoute();

  const created = function () {
    query.value = getSearchBarInitialValue(route.query);
  };
  created();
}
