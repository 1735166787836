import { split, pipe, map, join } from 'ramda';
import { setCookie, getCookie, clearOldVersionCookies } from '@/helper/cookie';
import { generateBaseArray, random } from '@/helper/random';
import { generateTypesEnum } from '@/constant/ab-test/ab-test';
import { isStringNumber } from '@/helper/data-process';

const abTestQueryEnum = {
  a: 'a',
  b: 'b',
  origin: 'o',
};

const defaultCookieName = 'ab_eslite_exp_default';
const randomIdCookieName = 'exp_generate_id';

/**
 * 權重式機率 (ex. 30%/30%/60%) 取得 exp 值
 */
const generateABTestBase = (aLength, bLength) => {
  const a = generateBaseArray(abTestQueryEnum.a, aLength);
  const b = generateBaseArray(abTestQueryEnum.b, bLength);
  const o = generateBaseArray(abTestQueryEnum.origin, 100 - aLength - bLength);
  // 測試 preview 用
  // console.log(a.concat(b).concat(o));
  return a.concat(b).concat(o);
};

const generateExpByProbability = (aPercent, bPercent) => {
  const data = generateABTestBase(aPercent, bPercent);
  const index = random(99, 0);
  return data[index];
};

/**
 * 以秒數隨機取得 exp 值 (機率 a=b=o)
 */
function generateExpByTime() {
  const seconds = new Date().getSeconds();
  const queryValues = Object.values(abTestQueryEnum);
  return queryValues[seconds % queryValues.length];
}

/**
 * 根據 client Id (or a string)從權重佔比中取得 exp
 * ex. 樣本(20%, 20%, 60%) 中以 client Id 去計算 index
 * 優點：同 client Id 可以獲得相同結果
 * 缺點：樣本受 client Id 規則影響
 * @param number
 * @returns {number}
 */
const toIndex = (number) => number % 100;
const syntaxToNumber = pipe(
  (syntax) => split('')(syntax),
  map((char) => char.charCodeAt(0)),
  (collection) => join('')(collection),
);

// 建立臨時 Id 存在 cookie
const createRandomId = () => {
  const id = getCookie(randomIdCookieName);
  if (id) return parseInt(id, 10);
  const randomId = random(0, 99);
  setCookie(randomIdCookieName, randomId);
  return randomId;
};

// 將 client Id 處理成預期中的數字格式
const formatClientIdToNumber = (clientId) => {
  if (clientId === '' || !clientId) return createRandomId();
  if (typeof clientId === 'number') return clientId;
  if (isStringNumber(clientId)) return parseInt(clientId, 10);
  return parseInt(syntaxToNumber(clientId), 10);
};

// 將 client Id 變成 index
const getIndexFromClientId = pipe(formatClientIdToNumber, toIndex);

const generateExpByClientId = (aPercent, bPercent, clientId, isClientIdRequired = true) => {
  // 當 client id 沒有時，無法計算，固定回 `o`; ex.ga client id is required.
  const isEmptyClientId = clientId === '' || !clientId;
  if (isClientIdRequired && isEmptyClientId) return abTestQueryEnum.origin;
  // 取 exp 號
  const data = generateABTestBase(aPercent, bPercent);
  const index = getIndexFromClientId(clientId);
  return data[index];
};

/**
 * process functions
 * @type {{[generateTypesEnum.average]: (function(): string), [generateTypesEnum.clientId]: string, [generateTypesEnum.probability]: (function(*, *): *)}}
 */
const functions = {
  [generateTypesEnum.average]: generateExpByTime,
  [generateTypesEnum.probability]: generateExpByProbability,
  [generateTypesEnum.clientId]: generateExpByClientId,
};

/**
 * AbTestService
 * getExperience()
 *   type = average，可以不用參數
 *   type = probability 需給 a/b 權重比，例如 probability(20,20)
 *   type = client id  需給 a/b 權重比 & client ID
 *
 */
export default class AbTestService {
  #cookie;

  #type;

  #process;

  #prefix = 'ab_';

  #suffix = '_05';

  /**
   * @param type A/B Test 樣本數量處理方式
   * @param cookieName cookies Name (用於使用者體驗不跳動的控制）
   */
  constructor(type = generateTypesEnum.average, cookieName = defaultCookieName) {
    this.#type = type;
    this.#process = functions[type];
    this.#cookie = `${this.#prefix}${cookieName}${this.#suffix}`;
  }

  isFlagExist() {
    const flag = getCookie(this.#cookie);
    return !!flag;
  }

  /**
   * 流程控制用：覆寫 cookie
   * @param exp A/B 環境
   */
  overwriteABTestCookies(exp) {
    setCookie(this.#cookie, exp);
  }

  /**
   * 取得 exp 值，參數請參考各 functions
   */
  getExperience(...args) {
    // pre exp
    const preExpValue = getCookie(this.#cookie);
    if (preExpValue) return preExpValue;
    // new exp
    const newExpValue = this.#process(...args);
    setCookie(this.#cookie, newExpValue);
    clearOldVersionCookies(this.#prefix, this.#suffix);
    return newExpValue;
  }
}
