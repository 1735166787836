<template>
  <div v-if="notInlineApp" class="header">
    <div class="header-bg-color" data-test-id="header">
      <div class="tw-container lg:tw-mt-1 lg:tw-p-0 tw-m-auto tw-my-0">
        <div class="tw-row eslite-header lg:!tw-pr-0">
          <div class="logo-wrapper tw-col-6 lg:tw-col-2">
            <router-link to="/" title="誠品線上" class="navbar-brand">
              <div class="logo"></div>
            </router-link>
          </div>
          <login-header class="header-login tw-col-2 tw-offset-2 lg:tw-mx-0 tw-px-0"></login-header>
        </div>
      </div>
    </div>
  </div>
  <router-view :key="$route.path" />
  <eslite-footer v-if="isShowFooter"></eslite-footer>
  <loading v-if="getLoadingStatus"></loading>
</template>

<script>
import { computed, inject, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useLoadingStore } from '@/stores/loading';
import LoginHeader from '@/components/layout/login-header.vue';
import EsliteFooter from '@/components/layout/footer.vue';
import Loading from '@/components/utility/loading.vue';
import checkIsInlineApp from '@/helper/check-inline-app';

const setup = () => {
  const $screen = inject('$screen');
  const loadingStore = useLoadingStore();
  const { getLoadingStatus } = storeToRefs(loadingStore);
  const notInlineApp = ref(false);
  const isShowFooter = computed(() => $screen.isDesktopSize);

  notInlineApp.value = !checkIsInlineApp();

  return {
    notInlineApp,
    getLoadingStatus,
    isShowFooter,
  };
};
export default {
  name: 'Market',
  components: {
    LoginHeader,
    EsliteFooter,
    Loading,
  },
  setup,
};
</script>

<style lang="scss" scoped>
@import '@/assets/mixins/layout/header.scss';
.eslite-header {
  justify-content: space-between;
}
.header {
  position: relative;
}
.header-bg-color {
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
}

@include mediaMin($grid-breakpoints-lg) {
  .header {
    height: 58px; // for fixed header space
  }
  .header-bg-color {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
  }
}

@include mediaMin($grid-breakpoints-xl) {
  .header {
    height: 70px; // for fixed header space
  }
}

@include mediaMax($grid-breakpoints-lg) {
  .header {
    height: 57px; // for mobile fixed header space
  }
  .logo {
    width: 127px;
    left: 0.5rem;
    right: auto;
  }
  .logo-wrapper {
    order: 1;
    margin-right: auto;
  }
  .header-login {
    order: 2;
    justify-content: space-around;
    flex: 0 0 5rem;
    max-width: 5rem;
  }
  .eslite-header {
    justify-content: flex-start;
  }
  .header-bg-color {
    background-color: var(--white);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
  }
  .logo {
    background-image: url('@/static/images/logo_r.webp');
    background-position: center;
  }
}
</style>
