<template>
  <nav v-if="hasItems" ref="menuElem" class="elevator-menu">
    <template v-for="(item, index) in items" :key="index">
      <div :title="item.name" :class="{ active: currentTab === index }" class="menu-element" @click="goto(index)">
        {{ item.name }}
      </div>
    </template>
  </nav>
</template>

<script setup>
import throttle from 'lodash-es/throttle';
import { ref, computed, onBeforeUnmount, onMounted, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useHomeAd2024Store } from '@/stores/home-ad-2024';
import { isEmptyValue } from '@/helper/data-process';
import { delay } from '@/helper/promise';

const homeAdStore = useHomeAd2024Store();
const { getNewThreadGroup } = storeToRefs(homeAdStore);

const menuElem = ref(null);
const currentTab = ref(0);
const coordinates = ref([]);

const items = computed(() => getNewThreadGroup?.value?.items || []);
const hasItems = computed(() => items.value?.length > 0);

const goto = (index) => {
  const id = `#js-affix-link-${index}`;
  const elem = document.querySelector(id);
  if (isEmptyValue(elem)) return false;
  const { offsetTop } = elem;
  if (isEmptyValue(offsetTop) || offsetTop <= 0) return false;
  window.scrollTo({ top: elem.offsetTop, behavior: 'smooth' });
  currentTab.value = index;

  return true;
};

const getCoordinates = async () => {
  if (hasItems.value === false) coordinates.value = [];

  await delay(() => {}, 1000);

  const elems = document.querySelectorAll('.thread-element');
  if (elems !== null && elems.length > 0) {
    /** @const {number} paddingTop 預置高度: 400 ~= 距離 header 230px */
    const paddingTop = 400;
    coordinates.value = [...elems].map((x) => x.offsetTop - paddingTop);
  }

  return coordinates.value;
};

/** @const {method} getScrollInterval 取得目前所在樓層 */
function getScrollInterval(scrollY, arr) {
  let left = 0;
  let right = arr.length - 1;

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);

    if (scrollY < arr[mid]) {
      right = mid - 1;
    } else if (scrollY >= arr[mid] && (mid === arr.length - 1 || scrollY < arr[mid + 1])) {
      return mid;
    } else {
      left = mid + 1;
    }
  }

  return -1;
}

const scrollEvent = throttle(function () {
  if (isEmptyValue(menuElem.value) || menuElem.value === undefined || coordinates.value.length <= 0) return;

  const { scrollY } = window;

  // 滾動300距離就顯示按鈕
  menuElem.value?.classList[scrollY > coordinates.value[0] ? 'add' : 'remove']('show');

  // 計算電梯按鈕 active 位置
  currentTab.value = scrollY > coordinates.value[0] ? getScrollInterval(scrollY, coordinates.value) : 0;
}, 200);

onBeforeUnmount(() => {
  window.removeEventListener('scroll', scrollEvent);
});

onMounted(() => {
  window.addEventListener('scroll', scrollEvent);
  watch(hasItems, getCoordinates);
});
</script>

<style scoped>
.elevator-menu {
  @apply tw-w-[50px] tw-p-0 tw-m-0 tw-mr-[688px];
  @apply tw-fixed tw-text-center tw-hidden tw-z-100;
  top: 138px;
  right: 50%;

  &.show {
    @apply tw-block;
  }

  @media (max-width: 1479px) {
    @apply tw-hidden;
    &.show {
      @apply tw-hidden;
    }
  }

  .menu-element {
    @apply tw-w-[50px] tw-h-[50px] tw-m-0 tw-mb-2 tw-p-1 tw-text-base tw-leading-[22px] tw-font-medium;
    @apply tw-flex tw-flex-col tw-justify-center tw-cursor-pointer tw-rounded tw-text-wrap tw-text-ellipsis tw-overflow-hidden;
    border: 1px solid theme('colors.gray-200');
    background-color: theme('colors.gray-50');
    color: theme('colors.gray-400');

    &:hover,
    &.active {
      color: theme('colors.white');
      background-color: theme('colors.gold-300');
    }
  }
}
</style>
