<template>
  <div v-if="!sticky" ref="keywordRef" class="tw-w-full">
    <ul v-if="hasKeywords" class="keywords left">
      <li v-for="(row, index) in rewriteList" :key="`header-keyword-${index}`">
        <RouterLinkUsage :link="row.link">{{ row.alt }}</RouterLinkUsage>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { computed, watch, ref, toRefs, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useHomeAd2024Store } from '@/stores/home-ad-2024';
import RouterLinkUsage from '@/components/utility/router-link-usage.vue';

const props = defineProps({ sticky: { type: Boolean, default: false } });
const { sticky } = toRefs(props);
const keywordRef = ref(null);

const homeAdStore = useHomeAd2024Store();
const { getSearchKeywords } = storeToRefs(homeAdStore);
const list = computed(() => getSearchKeywords.value?.items || []);

/** @const {number} averageCharWidthInPixels 預設每字佔用寬度(px) */
/** @const {number} containerWidth 外框寬度 */
const containerWidth = ref(700);
const rewriteList = ref([]);

// 估算
function calculateItems() {
  const avgWidth = { en: 8, cn: 14, padding: 22 };
  const result = list.value.reduce(
    (acc, item) => {
      if (acc.remainingWidth > 0) {
        const itemWidth = Array.from(item.alt).reduce((sum, char) => sum + (char.match(/[\u4e00-\u9fa5\u3400-\u4DBF]/) ? avgWidth.cn : avgWidth.en), avgWidth.padding);

        if (acc.remainingWidth - itemWidth >= 0) {
          acc.remainingWidth -= itemWidth;
          acc.count += 1;
        }
      }
      return acc;
    },
    { count: 0, remainingWidth: containerWidth.value },
  );

  // console.log(`在 ${containerWidth.value}px 寬的外框中，可以完整顯示 ${result.count} 個項目。`);
  rewriteList.value = list?.value?.filter((val, index) => index < result.count) || [];
}

const hasKeywords = computed(() => rewriteList.value.length > 0);

onMounted(() => {
  watch(list, calculateItems, { immediate: true });
});
</script>

<style scoped>
.keywords {
  @apply tw-w-full tw-pb-0 tw-mb-0 tw-text-13px tw-flex tw-items-center tw-overflow-hidden;

  li {
    @apply tw-px-[10px] tw-whitespace-pre tw-leading-4 tw-my-1 tw-text-center tw-no-underline;
    border-left: 1px solid theme('colors.gray-c');

    &:first-child {
      @apply tw-border-l-0 tw-ml-[1px] tw-pl-0;
    }
  }

  a {
    @apply tw-whitespace-pre tw-no-underline;
    color: theme('colors.gray-400');
    &:hover {
      color: theme('colors.gold-500');
    }
  }
}
</style>
