<template>
  <BannerBoxMobile :show-banner="hasItems" :title="title" class="carousel">
    <template #body>
      <ul v-if="formattedItems.length > 1" class="tabs">
        <li v-for="(tab, index) in formattedItems" :key="index" :class="{ active: currentTab === tab.index, empty: !tab.title }" @click="tab.index !== undefined && selectTab(tab.index)">
          {{ tab.title || '' }}
        </li>
      </ul>
      <div class="box">
        <div class="tw-w-full">
          <CardHorizontal :product="main" />
        </div>
        <div class="tw-w-full tw-grid tw-grid-cols-3 tw-gap-4">
          <card v-for="(product, pindex) in products" :key="pindex" :product="product" :mode-auto="true" />
        </div>
      </div>
    </template>
  </BannerBoxMobile>
</template>

<script setup>
import { ref, toRefs, computed, onMounted, watch } from 'vue';
import { map } from 'ramda';
import { usePriceAndImageStore } from '@/stores/price-and-image';
import BannerBoxMobile from '@/components/layout/elements/mobile/banner-box-mobile-2024index.vue';
import Card from '@/components/product/card-index2024.vue';
import CardHorizontal from '@/components/product/card-horizontal-and-desc.vue';

const props = defineProps({
  title: { type: String, default: '' },
  items: { type: Array, default: () => [], required: true },
});

const { title, items } = toRefs(props);

const hasItems = computed(() => items.value.length > 0);

const currentTab = ref(0);
const priceStore = usePriceAndImageStore();
const { fetchPrice } = priceStore;

const main = computed(() => items.value[currentTab.value]?.main || {});
const products = computed(() => items.value[currentTab.value]?.products?.slice(0, 6) || []);

const getProductPrices = () => {
  const ids = map((x) => x.esliteSN, items.value[currentTab.value]?.products || []);
  const mainId = main.value.esliteSN;
  const productIds = [...ids, mainId].filter((el) => el);
  if (productIds.length > 0) fetchPrice({ productIds });
};

const selectTab = (index, isClick = true) => {
  if (index === undefined || (currentTab.value === index && isClick === true)) return false;
  currentTab.value = index;
  getProductPrices();
  return true;
};

/** @const {function} tabsChange tabs 載入時需要做的部分 */
const tabsChange = (nv) => {
  if (nv === undefined) return false;
  currentTab.value = 0;
  selectTab(currentTab.value, false);
  return true;
};

const insertSpaces = (arr) =>
  arr.length > 1
    ? arr.reduce((acc, item, index) => {
        if (index > 0) acc.push({ title: '', index: undefined });
        acc.push({ title: item.title, index });
        return acc;
      }, [])
    : arr.map((item, index) => ({ title: item.title, index }));

const formattedItems = computed(() => insertSpaces(items.value));

onMounted(() => {
  watch(items, tabsChange, { immediate: true, deep: true });
});
</script>

<style scoped>
.tw-container {
  @apply tw-w-[1280px] tw-p-0 tw-m-0;
}

.tabs {
  list-style: none;
  display: flex;
  padding: 0;
  margin-bottom: 20px;
  overflow: auto;
}
.tabs li {
  cursor: pointer;
  flex-shrink: 0;
  @apply tw-p-[8px] tw-text-[14px] tw-leading-[22px] tw-font-medium tw-rounded;
  color: theme('colors.gray-400');
  &.active {
    background: theme('colors.gold-300');
    color: theme('colors.white');
  }
  &.empty {
    cursor: default;
    color: theme('colors.gray-200');
    line-height: 18px;
    &::before {
      font-size: 10px;
      content: '';
      border-right: 1px solid theme('colors.gray-200');
    }
  }
}

.box {
  @apply tw-w-full;

  a {
    @apply tw-flex;
  }
  img {
    @apply tw-w-[630px] tw-h-[420px];
  }
}
</style>
