import { ref, nextTick } from 'vue';
import { loadingStatusType } from '@/constant/loading-status-enum';
/**
 * adjustBoxWidth 自動計算寬度功能
 * @param refElem 外框元件 ref
 * @param subElemKey 框內元件關鍵字 (用於 querySelectorAll)
 * @param privStatus 狀態控制 ref
 * @param privRowCount 總行數 ref (default 0)
 * @param privContainerWidth 計算結果寬度 (px)
 * @param maxRowWidth 外框單行最大寬度
 * @param firstNumber 起始值 (外框左右 padding 加總)
 * @param keywordPadding 框內元件各自的左右間距
 */
export const adjustBoxWidth = (
  refElem = ref(null),
  subElemKey = '.keyword',
  privStatus = ref(loadingStatusType.wait),
  privRowCount = ref(0),
  privContainerWidth,
  maxRowWidth = 1000,
  firstNumber = 64,
  keywordPadding = 10,
) => {
  if (privStatus.value === loadingStatusType.full) return;
  nextTick(() => {
    const container = refElem.value;
    const keywordElements = container?.querySelectorAll(subElemKey) || [];
    if (keywordElements.length <= 0) return;

    let totalWidth = 0;
    for (let i = 0; i < keywordElements.length; i += 1) {
      totalWidth += keywordElements[i].offsetWidth + 1 * keywordPadding;
    }
    totalWidth += firstNumber;

    if (totalWidth <= maxRowWidth) {
      privContainerWidth.value = totalWidth;
      privStatus.value = loadingStatusType.full;
      privRowCount.value = 1;
      return;
    }

    privContainerWidth.value = maxRowWidth;
    let adjusted = true;
    let currentRowCount = 1;

    while (adjusted) {
      let firstRowWidth = 0;
      let secondRowWidth = 0;
      let currentRow = 1;
      let thirdRowWidth = 0;
      let thirdRowCount = 0;

      for (let i = 0; i < keywordElements.length; i += 1) {
        const keywordWidth = keywordElements[i].offsetWidth + 1 * keywordPadding;

        if (currentRow === 1) {
          if (firstRowWidth + keywordWidth <= privContainerWidth.value) {
            firstRowWidth += keywordWidth;
          } else {
            currentRow = 2;
            secondRowWidth += keywordWidth;
          }
        } else if (currentRow === 2) {
          if (secondRowWidth + keywordWidth <= privContainerWidth.value) {
            secondRowWidth += keywordWidth;
          } else {
            currentRow = 3;
            thirdRowWidth += keywordWidth;
            thirdRowCount += 1;
          }
        }
      }

      adjusted = false;

      if (thirdRowCount > 0) {
        if (thirdRowCount === 1) {
          privContainerWidth.value += thirdRowWidth;
        } else {
          privContainerWidth.value += thirdRowWidth / thirdRowCount;
        }
        adjusted = true;
      } else if (Math.abs(firstRowWidth - secondRowWidth) > maxRowWidth / 4) {
        privContainerWidth.value -= 50;
        adjusted = true;
      }

      currentRowCount = thirdRowCount > 0 ? 3 : 2;
    }

    privRowCount.value = currentRowCount;
    privStatus.value = loadingStatusType.full;
  });
};
