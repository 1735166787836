<template>
  <div v-if="showBanner" class="banner-box tw-bg-white" :class="{ full }">
    <div class="banner-box-title-row">
      <div class="title">{{ title }}</div>
      <slot name="subTitle" alt="同一層置右位置" />
    </div>
    <div class="banner-box-body">
      <slot name="body" alt="內容" />
    </div>
  </div>
</template>

<script setup>
/**
 * 首頁改版 banner 共用框架
 * 包含標題、標題按鈕組及版位內容
 */
import { toRefs } from 'vue';

const props = defineProps({
  /** @const {Boolean} showBanner 顯示版位 */
  showBanner: { type: Boolean, default: false },
  /** @const {String} title 版位標題 */
  title: { type: String, default: '' },
  /** @const {Boolean} full 內容滿版 body full */
  full: { type: Boolean, default: false },
});
const { showBanner, title, full } = toRefs(props);
</script>

<style scoped>
.banner-box {
  @apply tw-w-full tw-pt-5 tw-px-4 tw-pb-6 tw-mb-4;
  border-bottom: 1px solid theme('colors.gray-100');

  &.full {
    @apply tw-px-0;
  }
}

.banner-box-title-row {
  @apply tw-pb-5 tw-text-[24px] tw-leading-7 tw-font-bold;
  @apply tw-flex tw-justify-between tw-items-center;
}
.title {
  @apply tw-text-[20px] tw-leading-7 tw-font-bold;
  @apply tw-flex tw-justify-end;
  color: theme('colors.gray-500');
}

.banner-box-body {
  @apply tw-w-full tw-flex tw-flex-col;
}

.full .banner-box-title-row {
  @apply tw-px-4;
}
</style>
