import LocalStorageServices from '@/services/local-storage-services';
import LocalRecommendEventService from '@/services/search/local-recommend-event-service';

function createServiceInstance() {
  try {
    return new LocalRecommendEventService(new LocalStorageServices(Storage, localStorage));
  } catch (error) {
    console.log(error.message);
    return null;
  }
}

export function useLocalRecommendEvent() {
  const service = createServiceInstance();
  const getLocalRecommendEventLog = () => {
    const data = service?.get() || [];
    return data;
  };
  // 寫入搜尋紀錄 (Local)
  const setLocalRecommendEventLog = (keyword, token, list) => {
    if (!service) return;
    service.save(keyword, token, list);
  };

  const removeLocalRecommendEventLog = () => {
    if (!service) return;
    service.delete();
  };

  return {
    getLocalRecommendEventLog,
    setLocalRecommendEventLog,
    removeLocalRecommendEventLog,
  };
}
