import axios from 'axios';
import { isEmptyValue } from '@/helper/data-process';

const API_PATH = `${import.meta.env.VITE_HOLMES_EVENT}${import.meta.env.VITE_API_RETAIL_EVENTS}`;

const headers = (token) => {
  if (isEmptyValue(token) || token === 'false') return {};
  return {
    headers: { Authorization: token },
  };
};

export default (params, token) =>
  axios({
    method: 'post',
    url: API_PATH,
    data: params,
    ...headers(token),
  });
