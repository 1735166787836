<template>
  <div v-if="slides.length > 0" class="box">
    <div class="title">{{ title }}</div>
    <div v-if="!$isEmpty(slides)" class="products-wrapper">
      <RouterLinkUsage v-for="(item, index) in slides" :key="index" :link="item.link" :title="item.alt">
        <img v-lazy="item.image" v-default-image="defaultImage" :src="item.image" :alt="item.alt" />
      </RouterLinkUsage>
    </div>
  </div>
</template>

<script setup>
import { ref, toRefs, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useHomeAd2024Store } from '@/stores/home-ad-2024';
import RouterLinkUsage from '@/components/utility/router-link-usage.vue';
import { productDefaultWhiteImg } from '@/constant/images-path';

const defaultImage = ref(productDefaultWhiteImg);

const homeAdStore = useHomeAd2024Store();
const { getBankCardSlide } = storeToRefs(homeAdStore);
const slides = computed(() => getBankCardSlide.value?.items || []);

const props = defineProps({
  title: { type: String, default: '' },
});

const { title } = toRefs(props);
</script>

<style scoped>
@import url('@/assets/global-style/swiper.css');
.box {
  @apply tw-w-full tw-bg-white tw-pb-5 tw-mb-4;
  border-bottom: 1px solid theme('colors.gray-100');
}

.title {
  @apply tw-text-[20px] tw-leading-7 tw-font-bold tw-px-4 tw-py-5;
  color: theme('colors.gray-500');
}

.products-wrapper {
  @apply tw-p-4 tw-overflow-auto tw-flex tw-flex-row tw-justify-start;
  background-color: theme('colors.gray-50');

  a {
    @apply tw-mr-4 tw-block;

    &:last-child {
      @apply tw-mr-0;
    }

    img {
      @apply tw-w-[229px] tw-h-[132px] tw-rounded-lg;
    }
  }
}
</style>
