<template>
  <div class="product-card" :class="{ mode2, mode3, modeFull, modeAuto, modeBig }">
    <RouterLinkUsage v-if="hasProduct" :link="productLink" :title="productAlt">
      <div class="image-box tw-relative">
        <div v-if="!getType[productId]" class="card-image"><img :src="loadingLargeImg" class="tw-w-full" /></div>
        <product-lazy-image
          v-else
          :src="productImage"
          :is-adult="isRestricted"
          :alt="productAlt"
          :image-size="imageSize"
          :is-pre-order="isPreOrder"
          :error-img="errorImg"
          :restricted-img="restrictedImg"
          class="card-image"
        />
        <div v-if="discountPriceOnly" class="card-sale">
          <span class="discount bold">{{ discountPriceOnly }}</span>
          <span class="zhe">折</span>
        </div>
        <div v-if="top > 0" class="pennant" :class="{ gray: top > 3 }">
          <span class="top">TOP</span><span class="num">{{ top }}</span>
        </div>
      </div>

      <div class="product-card-text-box">
        <div v-if="(modeFull || modeBig) && !isEmptyValue(product.message1)" class="message">{{ product.message1 }}</div>
        <div v-if="(modeFull || modeBig) && !isEmptyValue(product.message2)" class="message">{{ product.message2 }}</div>

        <div v-if="mode2 && !isEmptyValue(top)" class="product-card-top">
          TOP<span>{{ top }}</span>
        </div>

        <div v-if="mode2" class="product-card-description card-mode2">
          <span v-if="isEbook" class="tag ebook-tag">電子書</span>{{ isEmptyValue(productTitle) ? '&nbsp;' : productTitle || '&nbsp;' }}
        </div>
        <div v-else class="product-card-title">{{ isEmptyValue(productTitle) ? '&nbsp;' : productTitle || '&nbsp;' }}</div>

        <p v-if="!mode2" class="product-card-description card-mode-default">
          <span v-if="isEbook" class="tag ebook-tag">電子書</span>{{ isEmptyValue(productName) ? '&nbsp;' : productName || '&nbsp;' }}
        </p>

        <div class="dollar-box">
          <span class="sale-dollar">{{ priceFormat(getPrices[productId]) }}</span>
          <span v-if="discountPriceOnly" class="origin-dollar">{{ priceFormat(getPrePrices[productId]) }}</span>
        </div>
      </div>
    </RouterLinkUsage>
  </div>
</template>

<script setup>
import { ref, toRefs, computed, watch, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { usePriceAndImageStore } from '@/stores/price-and-image.js';
import RouterLinkUsage from '@/components/utility/router-link-usage.vue';
import ProductLazyImage from '@/components/utility/product/image/product-lazy-image.vue';
import productPrice from '@/composables/product/product-price';
import { isEmptyValue } from '@/helper/data-process';
import priceFormat from '@/helper/filter/price-format';
import { loadingLargeImg, productFail, productRestricted, productFail628x418, productRestricted628x418 } from '@/constant/images-path';

const props = defineProps({
  product: { type: Object, default: () => ({}) },
  mode2: { type: Boolean, default: false },
  mode3: { type: Boolean, default: false },
  modeFull: { type: Boolean, default: false },
  modeAuto: { type: Boolean, default: false },
  modeBig: { type: Boolean, default: false },
  top: { type: Number, default: null },
});
const { product, mode2, mode3, modeFull, modeAuto, modeBig, top } = toRefs(props);

const priceAndImageStore = usePriceAndImageStore();
const { getPrices, getPrePrices, getImages, getType } = storeToRefs(priceAndImageStore);

const productPriceRef = ref(null);

/** @const {string} productId 商品 id */
const productId = computed(() => product.value?.esliteSN || product.value?.id || product.value?.guid || product.value?.product_guid || '');
/** @const {string} hasProduct 有商品？ */
const hasProduct = computed(() => !isEmptyValue(productId.value));
/** @const {string} productLink 商品連結 */
const productLink = computed(() => (hasProduct.value === false ? '' : product.value.link || product.value.url || `/product/${productId.value}`));
/** @const {string} productTitle 行銷標語 */
const productTitle = computed(() => product.value?.title || '');
/** @const {string} productAlt Alt提示 */
const productAlt = computed(() => product.value?.alt || productTitle.value);
/** @const {string} productName 商品名稱 */
const productName = computed(() => product.value?.subTitle || product.value?.sub_title || product.value?.name || '');
/** @const {string} productImageType 商品圖片類型 */
const productImageType = computed(() => {
  if (getType.value[productId.value]?.restricted === true) return 'restricted'; // 是18禁商品
  if (getType.value[productId.value]?.restricted === false) return 'default'; // 一般圖片
  return 'loading'; // loading 圖
});
/** @const {boolean} isRestricted 是否為18禁商品 */
const isRestricted = computed(() => productImageType.value === 'restricted' || false);
/** @const {boolean} isPreOrder 是否為預購商品 */
const isPreOrder = computed(() => getType.value[productId.value]?.preOrder || false);
/** @const {boolean} isEbook 是否為電子書 */
const isEbook = computed(() => getType.value[productId.value]?.ebook || false);
/** @const {string} productImage 商品圖 */
const productImage = computed(() => product.value?.image || getImages.value[productId.value]?.medium || '');
const imageSize = computed(() => {
  if (mode2.value || mode3.value) return 180;
  return 200;
});
const discountPriceOnly = computed(() => productPriceRef.value?.discountPriceOnly || undefined);

const errorImg = computed(() => (modeBig.value ? productFail628x418 : productFail));
const restrictedImg = computed(() => (modeBig.value ? productRestricted628x418 : productRestricted));

onMounted(() => {
  watch(
    productId,
    () => {
      if (!isEmptyValue(productId.value)) productPriceRef.value = productPrice({ id: productId.value, mode: 'price-and-image' });
    },
    { immediate: true },
  );
});
</script>

<style scoped>
.product-card {
  @apply tw-w-[200px] tw-h-[310px] tw-bg-white tw-overflow-hidden tw-rounded-t-lg;
  /* outline: 1px solid green; */
}

.card-image {
  @apply tw-w-[200px] tw-h-[200px] tw-object-contain tw-rounded-lg;
  /* background-color: theme('colors.gray-50'); */
  /* outline: 1px solid yellow; */
}

.card-sale {
  @apply tw-absolute tw-top-0 tw-left-0 tw-text-sm tw-px-2 tw-py-1 tw-rounded-tl-lg;
  color: theme('colors.white');
  background: theme('colors.pink-500');
}
.product-card-text-box {
  @apply tw-pt-2;
}

.product-card-title {
  @apply tw-text-sm tw-font-medium tw-text-nowrap tw-overflow-hidden tw-text-ellipsis;
  color: theme('colors.gold-500');
}
.product-card-description {
  @apply tw-h-[44px] tw-not-italic tw-text-[16px] tw-leading-[23px] tw-font-normal tw-my-2 tw-line-clamp-2 tw-text-ellipsis tw-overflow-hidden tw-break-all;
  color: theme('colors.gray-500');
}
.sale-dollar {
  @apply tw-text-[18px] tw-leading-[22px] tw-not-italic tw-font-bold;
  color: theme('colors.red-600');
  &::before {
    content: '$';
  }
}
.origin-dollar {
  @apply tw-text-[12px] tw-leading-[21px] tw-not-italic tw-font-normal tw-line-through tw-ml-1;
  color: theme('colors.gray-300');
  &::before {
    content: '$';
  }
}
.dollar-box {
  @apply tw-flex tw-items-center;
}

a:active,
a:hover,
a:visited {
  @apply tw-no-underline;

  .product-card-title {
    color: theme('colors.gold-500');
  }
  .sale-dollar {
    color: theme('colors.red-600');
  }
  .origin-dollar {
    @apply tw-line-through;
    color: theme('colors.gray-300');
  }
  .product-card-description {
    color: theme('colors.gray-500');
  }
}

.tag {
  @apply tw-px-[6px] tw-py-[2px] tw-text-[13px] tw-leading-[18px] tw-font-medium tw-rounded;

  &.preorder-tag {
    color: theme('colors.white');
    background-color: theme('colors.gold-a5');
  }
  &.ebook-tag {
    color: theme('colors.teal-500');
    background-color: theme('colors.teal-50');
  }
  & + .tag {
    @apply tw-ml-[2px];
  }
  &:last-child {
    @apply tw-mr-1;
  }
}

/** 略瘦版本 */
.mode2,
.mode3 {
  &.product-card {
    @apply tw-w-[180px] tw-h-[300px];
    @apply tw-rounded-lg;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  }
  .card-image {
    @apply tw-w-[180px] tw-h-[180px];
  }
  .product-card-text-box {
    @apply tw-p-2;
  }
  .pennant {
    @apply tw-w-[30px] tw-h-[40px] tw-text-[10px] tw-leading-[10px] tw-font-medium tw-flex tw-flex-col;
    @apply tw-absolute tw-top-0 tw-right-3 tw-pt-[2px] tw-mx-auto tw-text-center tw-tracking-[0.8px];
    background: url('@/static/images/icons/pennant-gold.svg') no-repeat;
    color: theme('colors.white');

    &.gray {
      background: url('@/static/images/icons/pennant-gray.svg') no-repeat;
    }

    span.top {
      transform: scale(0.9);
    }
    span.num {
      @apply tw-text-[16px] tw-leading-4 tw-font-bold tw-block;
    }
  }
}
.product-card-top {
  @apply tw-h-[18px] tw-text-[18px] tw-leading-[22px] tw-font-semibold;
  @apply tw-flex tw-items-center;
  color: theme('colors.gray-500');
  span {
    @apply tw-text-[24px] tw-leading-7 tw-pl-[2px];
  }
}

.modeBig {
  &.product-card {
    @apply tw-w-full tw-h-auto tw-rounded-lg tw-shadow-none;
  }
  .card-image {
    @apply tw-w-full tw-h-auto;
  }
  :deep(.product-image) {
    @apply tw-h-[418px] tw-pt-0;
  }
  .product-card-text-box {
    @apply tw-pt-4;

    .message {
      @apply tw-text-[20px] tw-leading-6 tw-font-bold tw-not-italic tw-mb-3 tw-truncate;
      color: theme('colors.gold-500');
    }
    .product-card-title {
      @apply tw-text-[18px] tw-leading-[23px] tw-font-bold tw-not-italic tw-mb-3 tw-truncate;
      color: theme('colors.gray-500');
    }
    .product-card-description {
      @apply tw-text-base tw-leading-[23px] tw-font-normal tw-not-italic tw-mt-0 tw-mb-4 tw-overflow-hidden tw-break-words;
      color: theme('colors.gray-400');
    }
    .dollar-box {
      @apply tw-flex tw-flex-row tw-justify-start tw-items-center;
    }
    .sale-dollar {
      @apply tw-text-[24px] tw-leading-6;
    }
    .origin-dollar {
      @apply tw-text-base tw-leading-[0px] tw-font-normal tw-tracking-[0.8px] tw-not-italic;
      color: theme('colors.gray-300');
      font-feature-settings: 'clig' off, 'liga' off;
      text-decoration-line: strikethrough;
    }
  }
}

.modeFull {
  &.product-card {
    @apply tw-w-full tw-h-auto tw-rounded-lg tw-shadow-none;
  }
  .card-image {
    @apply tw-w-full tw-h-auto;
  }
  .product-card-text-box {
    @apply tw-pt-4;

    .message {
      @apply tw-text-[20px] tw-leading-6 tw-font-bold tw-not-italic tw-mb-4;
      color: theme('colors.gold-500');
    }
    .product-card-title {
      @apply tw-text-[18px] tw-leading-[23px] tw-font-bold tw-not-italic tw-mb-4;
      color: theme('colors.gray-500');
    }
    .product-card-description {
      @apply tw-text-base tw-leading-[23px] tw-font-normal tw-not-italic tw-mt-0 tw-mb-4 tw-overflow-hidden tw-break-words;
      color: theme('colors.gray-400');
    }
    .dollar-box {
      @apply tw-flex tw-flex-row tw-justify-start tw-items-center;
    }
    .sale-dollar {
      @apply tw-text-[24px] tw-leading-6;
    }
    .origin-dollar {
      @apply tw-text-base tw-leading-[0px] tw-font-normal tw-tracking-[0.8px] tw-not-italic;
      color: theme('colors.gray-300');
      font-feature-settings: 'clig' off, 'liga' off;
      text-decoration-line: strikethrough;
    }
  }
}

.modeAuto {
  &.product-card {
    @apply tw-w-full tw-h-auto tw-rounded-lg tw-shadow-none;
  }
  .card-image {
    @apply tw-w-full tw-h-auto;
  }
  .product-card-text-box {
    @apply tw-pt-2 tw-px-2;

    .product-card-title {
      @apply tw-text-[13px] tw-leading-[22px] tw-font-bold tw-not-italic tw-overflow-hidden;
      color: theme('colors.gold-500');
    }
    .product-card-description {
      @apply tw-text-base tw-leading-[23px] tw-font-normal tw-not-italic tw-mt-0 tw-mb-2 tw-overflow-hidden;
      color: theme('colors.gray-400');
    }
    .dollar-box {
      @apply tw-flex tw-flex-row tw-justify-start tw-items-center tw-mb-2;
    }
    .sale-dollar {
      @apply tw-text-[16px] tw-leading-[22px];
      @media (max-width: 380px) {
        @apply tw-text-[15px];
      }
    }
    .origin-dollar {
      @apply tw-text-[12px] tw-leading-[18px] tw-font-normal tw-tracking-[0.8px] tw-not-italic;
      color: theme('colors.gray-400');
      font-feature-settings: 'clig' off, 'liga' off;
      text-decoration-line: strikethrough;
      @media (max-width: 380px) {
        @apply tw-text-[11px];
      }
    }
  }
}
</style>
