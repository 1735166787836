import { join } from 'ramda';
import { sha256 } from 'js-sha256';

export default class FingerprintService {
  /**
   * @type {ClientJS}
   */
  #package = null;

  constructor(clientJS) {
    this.#package = clientJS;
    return this;
  }

  #getClientInfo() {
    const userAgent = this.#package.getUserAgent() || '';
    const screenPrint = this.#package.getScreenPrint() || '';
    const colorDepth = this.#package.getColorDepth() || '';
    const mimeTypes = this.#package.getMimeTypes() || '';
    const canvasPrint = this.#package.getCanvasPrint() || '';
    const timeZone = this.#package.getTimeZone() || '';
    const systemLang = this.#package.getSystemLanguage() || '';
    const plugins = this.#package.getPlugins() || '';

    return [userAgent, screenPrint, colorDepth, mimeTypes, canvasPrint, timeZone, systemLang, plugins];
  }

  get() {
    const zipClient = join(',', this.#getClientInfo());
    return sha256(zipClient);
  }
}
