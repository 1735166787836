import { map, mergeAll, zipObj, fromPairs } from 'ramda';

/**
 * 建立對應物件
 * @param {[object]} types
 * @param {string} keyName 呼叫名稱
 * @param {string} returnName 回傳結果名稱
 * @return {[object]} 重置過的對應表
 */
export default (types = [], keyName = 'key', returnName = 'type') => mergeAll(map((x) => zipObj([x[keyName]], [x[returnName]]), types));

/**
 * 將陣列轉為物件
 * @param data {['a', 'b']}
 * @returns {{a: 'a', b: 'b'}}
 */
export const toObjectKeys = (data) => fromPairs(data.map((key) => [key, key]));
