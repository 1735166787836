<template>
  <icon-confirm-modal class="logout-modal" button-text="我知道了" theme="outline-gold-500" :icon="modalIconStyle" @close="$emit('close')">
    <template #body>
      <div class="tw-text-center">
        <span class="notice-title sans-font">您已成功登出</span>
        <span class="notice-content sans-font">提醒您，若您有使用其他誠品帳號服務(例：誠品電子書櫃)</span>
        <span>請記得至該服務功能一並將帳號登出，謝謝。</span>
      </div>
    </template>
  </icon-confirm-modal>
</template>
<script setup>
import iconConfirmModal from '@/components/utility/modal/icon-confirm-modal.vue';

const modalIconStyle = { type: 'icon-fa-check-circle', textTheme: 'green-300', textSize: '64px' };
</script>
<script>
export default {
  name: 'LogoutModal',
  emits: ['click', 'close'],
};
</script>

<style scoped lang="scss">
span {
  display: block;
}
.notice-title {
  @apply tw-font-bold tw-text-[16px] tw-leading-[22px] tw-text-gray-800;
}
.notice-content {
  @apply tw-font-normal tw-text-[14px] tw-leading-[20px] tw-text-gray-500;
}
</style>
