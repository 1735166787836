<template>
  <ul class="tw-flex">
    <li v-if="isLogin" class="gold">{{ encryptName(privacyUserName) }}</li>
    <li v-if="isLogin"><a href="javascript:void(0);" title="登出" @click="logout">登出</a></li>
    <li v-if="!isLogin"><RouterLinkUsage link="/login" title="登入">登入</RouterLinkUsage></li>
    <li v-if="!isLogin"><a href="javascript:void(0);" title="註冊" data-test-id="registry-button" @click="toRegistryLink">註冊</a></li>
    <li><RouterLinkUsage link="/member" title="會員中心">會員中心</RouterLinkUsage></li>
    <li><RouterLinkUsage link="/member/orders" title="我的訂單">我的訂單</RouterLinkUsage></li>
    <li><RouterLinkUsage link="/member/wish" title="收藏商品">收藏商品</RouterLinkUsage></li>
    <li><RouterLinkUsage link="/member/coupons" title="優惠券">優惠券</RouterLinkUsage></li>
    <!-- <li><RouterLinkUsage link="/member/?我的徽章連結" title="我的徽章">我的徽章</RouterLinkUsage></li> -->
    <li><RouterLinkUsage link="/member/points" title="誠品點">誠品點</RouterLinkUsage></li>
    <li><RouterLinkUsage link="/cart">購物車(<span class="red" :class="{ empty: cartQuantity === 0 }">{{ cartQuantity }}</span>)</RouterLinkUsage></li>
  </ul>
</template>

<script setup>
/**
 * 2024首頁改版 header links
 */
import { computed, inject, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useLoginStore } from '@/stores/login';
import { useCartItemsStore } from '@/stores/cart-items';
import { useLogout } from '@/composables/member/logout';
import RouterLinkUsage from '@/components/utility/router-link-usage.vue';
import { isBetween, redirectPage } from '@/helper/system-maintenance';
import { encryptName } from '@/helper/member/member-info-encryption';

const router = useRouter();
const route = useRoute();
const auths = inject('$auths');
const cryptoUtility = inject('$cryptoUtility');
const { logout } = useLogout({ auths, route, router });

const loginStore = useLoginStore();
const isLogin = computed(() => loginStore.loggedIn);
const privacyUserName = computed(() => cryptoUtility.decrypt(loginStore.privacyUser?.name || ''));

const cartItemsStore = useCartItemsStore();
const { getTotalOfCartItems, getQuantity } = storeToRefs(cartItemsStore);
const totalOfCartItems = computed(() => getTotalOfCartItems.value);
const storeCartQuantity = computed(() => getQuantity.value);

const cartQuantity = computed(() => {
  // 未登入一律拿 local cart
  if (!isLogin.value) return totalOfCartItems.value;
  return storeCartQuantity.value;
});

/** @const {function} toRegistryLink 註冊頁網址 */
const toRegistryLink = () => (window.location.href = isBetween() ? redirectPage : `${import.meta.env.VITE_API}${import.meta.env.VITE_API_REGISTRY}`);

watch(
  isLogin,
  () => {
    if (isLogin.value) auths.verifyTokenAndInitUser(true);
  },
  { immediate: true },
);
</script>

<style scoped>
a,
img {
  @apply tw-w-full tw-max-w-full;
}

/** header link: 結構 */
li {
  border-left: 1px solid theme('colors.gray-c');
  @apply tw-leading-4 tw-my-1 tw-px-[12px] tw-text-center tw-no-underline tw-whitespace-pre;
}
li:first-child {
  @apply tw-pl-0;
}
li:last-child {
  @apply tw-pr-0;
}
li:first-child {
  border-left: none;
}

/** header link: default (灰色) */
li a {
  @apply tw-text-sm tw-font-medium;
  color: theme('colors.gray-6');

  &:active,
  &:hover {
    @apply tw-no-underline;
    color: theme('colors.gold-500');
  }
}
/** header link: 購物車數量 (紅字) */
span.red {
  color: theme('colors.pink-500');
  /* &.empty { color: theme('colors.gold-500'); } */
}
/** header link: 名字 (金字) */
li.gold {
  @apply tw-px-0 tw-text-sm tw-font-medium;
  color: theme('colors.gold-500');

  & + li {
    border-left: none;
  }
}

.links {
  @apply tw-max-w-[662px] tw-basis-[662px] tw-grid tw-items-center;
}
</style>
