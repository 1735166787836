<template>
  <div class="d-flex flex-column" style="min-height: 100vh">
    <eslite-header />
    <!-- 加側邊欄（category) -->
    <router-view :key="$route.path" style="flex: 1; min-height: 50vh" />
    <eslite-footer v-if="isShowFooter" />
    <back-to-top v-if="!isShowFooter" />
    <loading v-if="getLoadingStatus" />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useLoadingStore } from '@/stores/loading';
import EsliteHeader from '@/components/layout/header.vue';
import EsliteFooter from '@/components/layout/footer.vue';
import BackToTop from '@/components/layout/elements/back-to-top.vue';
import Loading from '@/components/utility/loading.vue';

const route = useRoute();
const loadingStore = useLoadingStore();
const { getLoadingStatus } = storeToRefs(loadingStore);
const isShowFooter = computed(() => route.path.match(/^\/product/));
</script>

<script>
export default { name: 'Eslite' };
</script>
