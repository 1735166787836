<template>
  <div v-if="hasCoverBanner && coverBannerIsActive" class="fixed-banner tw-text-white" :class="{ active: coverBannerIsActive }">
    <nav class="tw-mx-0">
      <div class="tw-absolute tw-w-full tw-h-full tw-bg-black/70" @click.prevent="fullFixedBanner"></div>
      <div class="tw-relative tw-w-full tw-h-full tw-max-w-[600px] tw-max-h-[800px]">
        <div class="close-btn" data-test-id="cover-banner-close-button" @click.prevent="fullFixedBanner"><i class="icon-fa-times"></i></div>
        <a id="header-fullbanner" :href="coverBanner.link" :target="target" :title="coverBanner.alt"> <webp-image :src="coverBanner.image" :alt="coverBanner.alt" @load="setTimeClose"></webp-image></a>
      </div>
    </nav>
  </div>
  <div v-if="hasCoverBannerWaterMark && waterMarkIsActive" class="little-banner">
    <div class="close-banner" data-test-id="cover-banner-water-mark-close-button" @click.prevent="closeFixedBanner">
      <div class="icon-fa-times"></div>
    </div>
    <a id="header-menu-fullbanner" :href="coverBannerWatermark.link" :target="targetLittle" :title="coverBannerWatermark.alt"
      ><img :src="coverBannerWatermark.image" :alt="coverBannerWatermark.alt"
    /></a>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useGlobalAdStore } from '@/stores/global-ad';
import getGlobalPage from '@/api/ad/global-page';
import WebpImage from '@/components/utility/image/webp-image.vue';
import { isEmptyValue, linkTarget } from '@/helper/data-process';
import dayjs from '@/helper/filter/date';

const globalAdStore = useGlobalAdStore();
const { getCoverBannerWatermark: coverBannerWatermark, getCoverBanner: coverBanner, getLittleBanner: littleBanner } = storeToRefs(globalAdStore);
const { setData: setAdData, setMobileData } = globalAdStore;

const coverBannerIsActive = ref(false);
const waterMarkIsActive = ref(false);
const showFixedBannerTimer = ref(null);
const showWatermarkTimer = ref(null);

const target = computed(() => linkTarget(coverBanner.value?.blank));
const targetLittle = computed(() => linkTarget(coverBannerWatermark.value?.blank));
const hasCoverBanner = computed(() => Object.keys(coverBanner.value).length > 0 && !isEmptyValue(coverBanner.value.image));
const haslittleBanner = computed(() => Object.keys(littleBanner.value).length > 0);
const hasCoverBannerWaterMark = computed(() => Object.keys(coverBannerWatermark.value).length > 0 && !isEmptyValue(coverBannerWatermark.value.image));

// 檢查時間有無過期
function actionWhenGetCoverBannerExpiredTime(expiredTime) {
  const isExpired = dayjs(dayjs().format('YYYY-MM-DD')).isAfter(dayjs(expiredTime).format('YYYY-MM-DD'));
  return isExpired;
}

// 檢查浮水印是否關閉
const getSwitchWaterMark = () => {
  if (JSON.parse(localStorage.getItem('onSwitchWaterMark')) === null) return true;
  return JSON.parse(localStorage.getItem('onSwitchWaterMark'));
};
// 啟用蓋版，最後再丟值到 localStorage
const activateCoverBanner = () => {
  coverBannerIsActive.value = true;
  localStorage.setItem('coverBannerTriggeredAt', new Date());
};
const getTriggerTime = (val) => localStorage.getItem(val);

// 啟用蓋版，最後再丟值到 localStorage
const activateWaterMark = () => {
  waterMarkIsActive.value = true;
  localStorage.setItem('onSwitchWaterMark', true);
  localStorage.setItem('waterMarkTriggeredAt', new Date());
};
// 手動關閉浮水印
const isCloseWaterMark = () => (waterMarkIsActive.value = getSwitchWaterMark());
// 6秒後再關起來
const setTimeClose = () => (showFixedBannerTimer.value = setTimeout(() => (coverBannerIsActive.value = false), 6000));
// 蓋板廣告是否過期，在做後續處理
const checkCoverBannerExpiredTime = (triggerTime) => {
  if (actionWhenGetCoverBannerExpiredTime(triggerTime)) {
    activateCoverBanner();
  } else coverBannerIsActive.value = false;
};
// 浮水印是否過期，在做後續處理
const checkWaterMarkExpiredTime = (triggerTime) => {
  if (actionWhenGetCoverBannerExpiredTime(triggerTime)) {
    activateWaterMark();
  } else {
    // 手動關閉
    isCloseWaterMark();
  }
};
const fullFixedBanner = () => {
  coverBannerIsActive.value = false;
  console.log({ IsActive: coverBannerIsActive.value, Timer: showFixedBannerTimer.value });
  if (showFixedBannerTimer.value !== null) clearTimeout(showFixedBannerTimer.value);
};
const closeFixedBanner = () => {
  waterMarkIsActive.value = false;
  if (showWatermarkTimer.value !== null) clearTimeout(showWatermarkTimer.value);
  localStorage.setItem('onSwitchWaterMark', false);
};

watch(
  hasCoverBanner,
  (val) => {
    if (!val) return;
    // 檢查時間
    const triggerTime = getTriggerTime('coverBannerTriggeredAt');
    if (!isEmptyValue(triggerTime)) {
      checkCoverBannerExpiredTime(triggerTime);
    } else activateCoverBanner();
  },
  { immediate: true },
);
watch(
  hasCoverBannerWaterMark,
  (val) => {
    if (!val) return;
    // 檢查時間
    const triggerTime = getTriggerTime('waterMarkTriggeredAt');
    if (!isEmptyValue(triggerTime)) {
      checkWaterMarkExpiredTime(triggerTime);
    } else activateWaterMark();
  },
  { immediate: true },
);

onMounted(async () => {
  // global ad
  const res = (await getGlobalPage()).data;
  if (Object.keys(littleBanner.value).length === 0) {
    setAdData(res);
    setMobileData(res);
  }
});
</script>

<style scoped>
/* 今日推薦 */
.fixed-banner {
  @apply tw-z-[700] tw-fixed tw-right-0 tw-bottom-[150px];

  @media (max-width: 991px) {
    @apply tw-bottom-[120px];
  }

  nav {
    @apply tw-w-full tw-h-full tw-top-0 tw-border-0 tw-hidden;
    background-color: transparent;
    a {
      @apply tw-w-full tw-h-full;
      img {
        @apply tw-w-full tw-h-full tw-max-w-[600px] tw-max-h-[800px] tw-object-contain;
      }
    }
  }
  .close-btn {
    @apply tw-w-[47.8px] tw-h-[47.8px] tw-text-center tw-flex tw-justify-center tw-items-center tw-text-[2rem] tw-absolute tw-top-0 tw-right-0 tw-cursor-pointer tw-select-none;
    border-radius: 50%;
    background-color: theme('colors.eslite-red');
    color: theme('colors.white');
    i {
      @apply tw-mx-0;
    }
  }
  &.active {
    @apply tw-w-full tw-h-full tw-top-0 tw-right-0;
    nav {
      @apply tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center;
      .bgc {
        background-color: rgba(0, 0, 0, 0.7);
        @apply tw-w-full tw-h-full tw-absolute;
      }
      .row {
        @apply tw-w-full tw-h-full tw-max-w-[600px] tw-max-h-[800px] tw-relative;
      }
    }
  }
}
.little-banner {
  @apply tw-w-[100px] tw-h-[100px] tw-mr-[5px] tw-z-[60] tw-fixed tw-right-0 tw-bottom-[150px];
  .icon-fa-times {
    color: theme('colors.white');
  }
  .close-banner {
    @apply tw-w-[29px] tw-h-[29px] tw-absolute tw-top-0 tw-right-0 tw-flex tw-justify-center tw-items-center tw-cursor-pointer;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
  }
  a {
    @apply tw-w-full tw-h-full;
    img {
      @apply tw-w-full tw-h-full tw-object-contain;
    }
  }
}
</style>
