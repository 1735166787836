<template>
  <div v-if="hasData" class="tw-mb-4 wrapper">
    <div v-for="(row, rowIndex) in twoDrow" :key="rowIndex" :class="{ 'cols-4': row.length <= 4, 'cols-5': row.length === 5 }" class="icons-row">
      <router-link-usage v-for="(item, index) in row" :key="index" :link="item.link" :title="item.alt" class="link">
        <span class="icon-wrapper tw-mb-1">
          <img :src="item.image" :alt="item.alt" />
        </span>
        <span class="tw-text-xs tw-h-[18px]">{{ item.alt }}</span>
      </router-link-usage>
    </div>
  </div>
</template>

<script setup>
import { toRefs, computed } from 'vue';
import RouterLinkUsage from '@/components/utility/router-link-usage.vue';

const transformTo2DArray = (arr) => {
  const length = Math.min(arr.length, 10);
  if (length <= 4) return [arr.slice(0, length)];
  const firstPartLength = length >= 9 ? 5 : 4;
  return [arr.slice(0, firstPartLength), arr.slice(firstPartLength, length)];
};

const props = defineProps({
  linkData: {
    type: Array,
    required: true,
  },
});

const { linkData } = toRefs(props);
const twoDrow = computed(() => transformTo2DArray(linkData.value.slice(0, 10)));
const hasData = computed(() => linkData.value.length > 0);
</script>

<style scoped>
.wrapper {
  padding: 20px 0;
  background-color: theme('colors.white');
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
}
.link {
  @apply tw-w-[50px] tw-flex tw-flex-col tw-text-center;
  &:hover {
    text-decoration: none;
  }
  span {
    @apply tw-block;
    word-break: keep-all;
    color: theme('colors.gray-500');
  }
  img {
    @apply tw-w-[50px];
  }
}

.icons-row {
  @apply tw-w-fit tw-grid tw-justify-items-center tw-pb-3 tw-m-auto;

  &.cols-4 {
    @apply tw-grid-cols-4 tw-gap-7 tw-px-[37px];
  }
  &.cols-5 {
    @apply tw-grid-cols-5 tw-gap-5 tw-px-[30px];
  }

  &:last-child {
    @apply tw-pb-0;
  }
}
</style>
