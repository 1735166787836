<template>
  <div id="scroll-to-top" ref="scrollToTopRef" @click="toTop">
    <img src="@/static/images/icons/direction-thick-arrow-up.svg" alt="" />
    <div class="text">Top</div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';

const scrollToTopRef = ref(null);

const scrollTopEvent = () => {
  const scrollY = window.scrollY || document.documentElement.scrollTop;
  scrollToTopRef.value.classList[scrollY > 400 ? 'add' : 'remove']('show');
};

const toTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

onBeforeUnmount(() => {
  window.removeEventListener('scroll', scrollTopEvent);
});

onMounted(() => {
  window.addEventListener('scroll', scrollTopEvent);
});
</script>

<style scoped>
#scroll-to-top {
  @apply tw-w-12 tw-h-12 tw-px-[14px] tw-py-1 tw-m-0 tw-text-sm tw-font-medium tw-rounded tw-flex-col tw-justify-center tw-items-center tw-cursor-pointer tw-select-none tw-hidden;
  background-color: theme('colors.gold-300');
  color: theme('colors.white');

  &.show {
    @apply tw-flex tw-fixed tw-ml-[688px] tw-z-10;
    bottom: 98px;
    right: 20px;
  }

  img {
    @apply tw-w-5 tw-h-5;
  }
  .text {
    margin-top: -2px;
  }

  &:hover {
    background-color: theme('colors.gold-500');
    color: theme('colors.white');
  }
}
</style>
