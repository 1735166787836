<template>
  <layout class="daily-recommended-wrapper">
    <template #header>
      <div class="container section-title px-0 align-items-center mb-2">
        <span class="title section-title-size ps-0 pb-2 serif-font-bold ps-3 ps-lg-0">今日最推</span>
        <span class="title icon-todayrecommend ms-1"></span>
      </div>
    </template>
    <template #left-content>
      <div class="container px-0">
        <recommend-swiper-mobile-v2 v-if="$screen.isMobileSize" source-i-d="home-todayrecommend-" />
        <recommend-swiper-desktop-v2 v-else source-i-d="home-todayrecommend-" is-skeleton-image />
      </div>
    </template>
    <template v-if="control.hotTopic" #right-content>
      <div class="container p-0">
        <hot-search />
        <member-banner />
      </div>
    </template>
  </layout>
</template>

<script>
import HotSearch from '@/components/index/hot-search.vue';
import Layout from '@/components/index/elements/layout.vue';
import RecommendSwiperDesktopV2 from '@/components/index/recommend-and-hot-topic-position/recommend-swiper-desktop-v2.vue';
import RecommendSwiperMobileV2 from '@/components/index/recommend-and-hot-topic-position/recommend-swiper-mobile-v2.vue';
import MemberBanner from '@/components/index/member-banner.vue';
import { homeDefaultOptionalBlocks } from '@/constant/page-control/home-control';

const components = {
  Layout,
  HotSearch,
  MemberBanner,
  RecommendSwiperDesktopV2,
  RecommendSwiperMobileV2,
};

const props = {
  control: {
    type: Object,
    default: () => homeDefaultOptionalBlocks.recommendAndHotTopicPosition,
  },
};

export default {
  name: 'RecommendAndHotTopicPosition',
  components,
  props,
};
</script>

<style lang="scss" scoped>
.title {
  color: #ff5c00;
  @include mediaMax($grid-breakpoints-lg) {
    font-size: 1.25rem;
  }
  @include mediaRange($grid-breakpoints-lg, $grid-breakpoints-xl) {
    font-size: 1.125rem;
  }
  @include mediaMin($grid-breakpoints-xl) {
    font-size: 1.25rem;
  }
}
#virtual-shelf-layout .daily-recommended-wrapper {
  .section-title {
    margin-bottom: 0 !important;
    .title {
      font-size: inherit;
    }
    .title:first-of-type {
      padding-bottom: 0 !important;
    }
  }
  :deep(.left-box) {
    padding-left: $virtual-shelf-spacer !important;
    padding-right: $virtual-shelf-spacer !important;
  }
}
</style>

<style lang="scss">
@include mediaRange($grid-breakpoints-lg, $grid-breakpoints-xl) {
  .daily-recommended-wrapper {
    .boxsize {
      height: 150px;
    }
  }
}
</style>
