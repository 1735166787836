<template>
  <div class="tw-w-full p-0">
    <div class="index-page index-container" :class="{ sticky: scrollMode }">
      <!-- face one -->
      <div class="row-menu-and-big-slide tw-w-full index-container"><MenuComponent class="menu" /><BigSlideComponent /></div>

      <!-- event B008, B009 -->
      <div v-if="!!mapBanner" class="row-custom-event-big tw-w-full index-container">
        <ImageMap block-name="main-strategy" :image-url="mapBanner.imageUrl" :map-data="mapBanner.items" :banner-width-base="1280" />
      </div>
      <div class="tw-w-full index-container"><SmallBannerSlideComponent title="攻略廣告區塊" class="tw-p-0" /></div>

      <!-- B010, B011 -->
      <div class="tw-w-full index-container"><ProductCardSlideComponent title="超划算限搶" :tabs="productCardSlide" /></div>
      <div class="tw-w-full index-container"><ProductCardSlideComponent title="新書焦點" :tabs="productCardSlides" /></div>

      <!-- B012 B013 -->
      <div class="tw-w-full index-container"><MemberBannerGroupComponent title="會員獨享" /></div>
      <!-- B014 熱門關鍵字 -->
      <div class="tw-w-full index-container"><HotKeywordsComponent /></div>
      <!-- B015 網路暢榜 -->
      <div class="tw-w-full index-container"><BestSellersOnlineComponent title="網路暢榜" /></div>
      <!-- B016 B017 B018 B019 主題企劃, banner, 限定商品, 獨家品牌 -->
      <div class="tw-w-full index-container"><MultiCarouselDisplaySectionComponent title="主題企劃" /></div>

      <!-- B020 -->
      <div class="tw-w-full index-container"><StrongRecommendationComponent title="強檔推薦" :tabs="strongRecommendation" /></div>
      <!-- B021 -->
      <div class="tw-w-full index-container"><BankCardSlideComponent title="銀行/行動支付優惠總覽" /></div>
      <!-- B022 -->
      <div class="tw-w-full index-container"><StrikingBrands title="注目品牌" /></div>
      <!-- B023 -->
      <div class="tw-w-full index-container"><BrandListComponent title="品牌列表" :items="getBrandList" /></div>
      <!-- B024 -->
      <div class="tw-w-full index-container"><TopicSelectionTabsComponent title="話題選品" :items="topicSelectionTabs" /></div>
      <!-- B025 -->
      <div v-if="wideBanner.length > 0" class="top-banner index-container tw-w-full tw-pt-0 tw-px-0 tw-pb-16">
        <wide-banner-component id="horizontal-swiper" :items="wideBanner" class="horizontal-slides p-0" name="horizontal" :source-i-d="`home-banner-`" />
      </div>

      <!-- B026 分類樓層 -->
      <div class="tw-w-full index-container"><ThreadGgroup /></div>
      <ThreadElevatorButtons />

      <BackToTop />
    </div>
  </div>
</template>

<script setup>
import { toRefs, computed, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useHomeAdStore } from '@/stores/home-ad';
import { useHomeAd2024Store } from '@/stores/home-ad-2024';
import { homePageADTypeEnum } from '@/constant/ad/homepage-ad-type';
import MenuComponent from '@/components/layout/elements/menu-component.vue';
import BigSlideComponent from '@/components/layout/elements/big-slide-component.vue';
import SmallBannerSlideComponent from '@/components/layout/elements/small-banner-slide.vue';
import ProductCardSlideComponent from '@/components/layout/elements/product-card-slide-component.vue';
import MemberBannerGroupComponent from '@/components/layout/elements/member-banner-group.vue';
import HotKeywordsComponent from '@/components/layout/elements/hot-keywords.vue';
import BestSellersOnlineComponent from '@/components/layout/elements/best-sellers-online.vue';
import MultiCarouselDisplaySectionComponent from '@/components/layout/elements/multi-carousel-display-section.vue';
import StrongRecommendationComponent from '@/components/layout/elements/strong-recommendation.vue';
import BankCardSlideComponent from '@/components/layout/elements/bank-card-slide-component.vue';
import StrikingBrands from '@/components/layout/elements/striking-brands.vue';
import BrandListComponent from '@/components/layout/elements/brand-list.vue';
import TopicSelectionTabsComponent from '@/components/layout/elements/topic-selection-tabs.vue';
import WideBannerComponent from '@/components/layout/elements/wide-banner.vue';
import ThreadGgroup from '@/components/layout/elements/thread-group.vue';
import ThreadElevatorButtons from '@/components/layout/elements/thread-elevator-buttons.vue';
import BackToTop from '@/components/layout/elements/back-to-top-v2.vue';
import ImageMap from '@/components/layout/elements/image-map.vue';

const homeAdStore = useHomeAdStore();
const { fetchHomeAd } = homeAdStore;
const homeAd2024Store = useHomeAd2024Store();
const { fetchHomeAd: fetchHomeAd2024 } = homeAd2024Store;
const { getProductCardSlide, getProductCardSlides, getStrongRecommendation, getBrandList, getTopicSelectionTabs, getWideBanner, getHtmlMapBanner } = storeToRefs(homeAd2024Store);

const defaultFaceTree2024 = [
  // #0: [第一頻 header, menu, 輪播, 活動兩個] B001, B003, B004, B005, B006, B007
  [homePageADTypeEnum.topBanner, homePageADTypeEnum.searchKeywords, homePageADTypeEnum.headerSmallBanner, homePageADTypeEnum.bigSlideTabs, homePageADTypeEnum.menu, homePageADTypeEnum.bigSlide],
  // #1 (event): B008, B009
  [homePageADTypeEnum.htmlMapBanner, homePageADTypeEnum.smallBannerSlide],
  // #2: B010, B011
  [homePageADTypeEnum.productCardSlide, homePageADTypeEnum.productCardSlides],
  // #3: B012, B013, B014
  [homePageADTypeEnum.membersOnly, homePageADTypeEnum.slideAndFourBanner, homePageADTypeEnum.hotKeywords],
  // #4: B016-B019
  [homePageADTypeEnum.newMainTopicProject, homePageADTypeEnum.threeBannerGroup, homePageADTypeEnum.limitedEditionGroup, homePageADTypeEnum.exclusiveBrandGroup],
  // #5: B020-B023
  [homePageADTypeEnum.strongRecommendation, homePageADTypeEnum.bankCardSlide, homePageADTypeEnum.strikingBrands, homePageADTypeEnum.brandList],
  // #6: B024-B025
  [homePageADTypeEnum.topicSelectionTabs, homePageADTypeEnum.wideBanner],
  // #7: B026
  [homePageADTypeEnum.newThreadGroup],
];

const defaultFaceTree = [
  // B015
  [homePageADTypeEnum.onlineLeaderboard],
];

const props = defineProps({ scrollMode: { type: Boolean, default: false } });
const { scrollMode } = toRefs(props);

const mapBanner = computed(() => getHtmlMapBanner.value?.web || null);

const productCardSlide = computed(() => (getProductCardSlide.value?.items === undefined || getProductCardSlide.value?.items.length === 0 ? [] : getProductCardSlide.value?.items || []));

const productCardSlides = computed(() => (getProductCardSlides.value?.items === undefined || getProductCardSlides.value?.items.length === 0 ? [] : getProductCardSlides.value?.items || []));

const strongRecommendation = computed(() =>
  getStrongRecommendation.value?.items === undefined || getStrongRecommendation.value?.items.length === 0 ? [] : getStrongRecommendation.value?.items || [],
);

const topicSelectionTabs = computed(() => (getTopicSelectionTabs.value?.items === undefined || getTopicSelectionTabs.value?.items.length === 0 ? [] : getTopicSelectionTabs.value?.items || []));
const wideBanner = computed(() => (getWideBanner.value?.items === undefined || getWideBanner.value?.items.length === 0 ? [] : getWideBanner.value?.items || []));

onMounted(() => {
  defaultFaceTree2024.forEach(async (val) => {
    await fetchHomeAd2024(val);
  });
  defaultFaceTree.forEach(async (val) => {
    await fetchHomeAd(val);
  });
});
</script>

<script>
export default { name: '2024Index' };
</script>

<style scoped>
@import url('@/assets/tailwind-2024index.css');

.index-page {
  @apply tw-p-0 tw-mx-auto tw-relative;
}

.row-custom-event-big {
  @apply tw-p-0 tw-mb-0;
}

.row-menu-and-big-slide,
.row-custom-event-small {
  @apply tw-p-0 tw-mb-16;
}

/** menu & big slide */
.row-menu-and-big-slide {
  display: flex;
  .menu {
    @apply tw-mr-3;
    width: 212px;
  }
  .slide {
    @apply tw-ml-3;
    width: 1044px;
    max-width: 1044px;
  }
}
</style>
