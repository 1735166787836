import { map, split, includes } from 'ramda';
import { isEmptyValue } from '@/helper/data-process';

const formatIds = map((x) => x.id);

const grepPath = (path) => {
  const pathGroup = split('/', path);
  return `${pathGroup[1] || ''}`.toLowerCase();
};

const enablePathEnum = {
  search: 'search',
  brand: 'brand',
  category: 'category',
};

export default class RetailEventService {
  #apiAction;

  #visitor;

  #token;

  #enableList = [enablePathEnum.search];

  constructor(action, visitor, token) {
    this.#apiAction = action;
    this.#visitor = visitor;
    this.#token = token;
  }

  #createRecommendPayload(params) {
    const token = params?.storage?.token;
    const keyword = params?.storage?.keyword;
    const order = params?.storage?.order;

    // 沒有 token 不需要 recommend 欄位
    if (isEmptyValue(token)) return {};

    // 沒有選擇「關鍵字」(ex. 看完列表後輸入清單上沒有的關鍵字）
    if (keyword !== params.keyword || order === -1) return {};

    return {
      completion_attribution_token: token,
      selected_suggestion: keyword,
      selected_position: order,
    };
  }

  #createPayload(event, exp, params) {
    return {
      event_type: event, // search|detail-page-view|add-to-cart|add-to-wish|add-to-notify|change-page|preview
      group: exp, // a | b
      search_query: params.keyword, // optional, searchKeyWord
      order: params.order, // nullable, 1-20
      id: params.id, // nullable, ProductId
      path: grepPath(params.path),
      visitor_id: this.#visitor,
      attribution_token: params.token,
      search_result_ids: isEmptyValue(params.products) ? undefined : formatIds(params.products),
      page_no: params.page,
      page_size: params.size,
    };
  }

  sendEvent(event, exp, params) {
    // 原本：節省帳單，exp = o 不 call
    // 有紀錄的可能
    // if (exp === expEnum.origin) {
    //   return createFakeResponse();
    // }

    // 只收特定 path 的資料
    // 目前暫不收品牌館、分類頁的 events
    const from = grepPath(params.path);
    if (!includes(from, this.#enableList)) return;

    return this.#apiAction(
      {
        ...this.#createPayload(event, exp, params),
        ...this.#createRecommendPayload(params),
      },
      this.#token,
    );
  }
}
