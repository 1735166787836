<template>
  <div :class="`${name}-modal`" data-test-id="single-button-modal">
    <transition name="modal">
      <div class="modal-mask" @click="onClick">
        <div class="modal-wrapper">
          <div class="modal-container tw-container tw-m-auto" :style="contentWidth">
            <div class="display-icon tw-text-center">
              <slot name="icon">
                <span :class="iconClass"></span>
              </slot>
            </div>
            <div class="body" :class="customBodyClass" :style="contentStyle">
              <slot name="body"></slot>
            </div>
            <div class="footer tw-text-center">
              <slot name="footer">
                <button ref="footerBtnRef" class="tw-rounded ec-btn" type="button" :style="buttonStyle" :class="buttonClass" @click="$emit('close')">{{ buttonText }}</button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref, onMounted, toRefs, computed } from 'vue';

const props = {
  name: {
    type: String,
    default: 'common',
  },
  width: {
    type: Number,
    default: 600,
  },
  customBodyClass: {
    type: String,
    default: '',
  },
  customContent: {
    type: Object,
    default: () => ({}),
  },
  customButton: {
    type: Object,
    default: () => ({}),
  },
  theme: {
    type: String,
    default: 'e-mid-gold',
  },
  buttonText: {
    type: String,
    default: '好',
  },
  icon: {
    type: Object,
    // sample : { type: 'icon-fa-check-circle', textTheme: 'green-300', textSize: '64px' }
    default: () => ({}),
  },
};

const setup = (props, { emit }) => {
  const footerBtnRef = ref(null);
  const { width, customContent, customButton, theme, buttonText, icon } = toRefs(props);

  /** computed */
  const buttonClass = computed(() => ({ [`ec-btn-${theme.value}`]: true }));
  const buttonStyle = computed(() => ({ ...customButton.value }));
  const contentWidth = computed(() => ({ width: `${width.value}px`, 'max-width': `95vw` }));
  const contentStyle = computed(() => ({ ...customContent.value }));
  const iconClass = computed(() => {
    const result = { icon: true, 'icon-color': true };
    if (icon.value.type) result[icon.value.type] = true;
    // if (icon.value.textTheme) result[`tw-text-${icon.value.textTheme}`] = true;
    // if (icon.value.textTheme) result[`before:tw-text-${icon.value.textTheme}`] = true;
    if (icon.value.textSize) result[`tw-text-[${icon.value.textSize}]`] = true;
    return result;
  });
  const onClick = () => emit('click');

  onMounted(() => {
    footerBtnRef.value?.focus();
  });
  return {
    footerBtnRef,
    theme,
    buttonText,
    /** computed */
    buttonClass,
    buttonStyle,
    contentWidth,
    contentStyle,
    iconClass,
    /** methods */
    onClick,
  };
};

export default {
  name: 'IconConfirmModal',
  props,
  emits: ['click', 'close'],
  setup,
};
</script>

<style lang="scss" scoped>
@import '@/assets/mixins/utility/modal.scss';
.logout-modal {
  .modal-container {
    @apply tw-py-10 tw-px-6;
    @apply tw-rounded-lg;
  }
  .icon-color {
    @apply tw-text-green-300;
  }
}
</style>
