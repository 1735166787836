<template>
  <div v-if="hotKeywords.length > 0" class="hot-keywords-box" :class="{ scroll: rowCount > 1 }" :style="{ 'background-color': getHotKeywords.color || '' }">
    <div class="bank-box">
      <img :src="getHotKeywords.image || ''" :alt="getHotKeywords.title || ''" />
    </div>
    <div class="scroll-box">
      <div class="keyword-shadow"></div>
      <div class="keywords-box">
        <div ref="containerRef" class="keywords-container" :style="{ width: containerWidth + 'px' }">
          <RouterLinkUsage v-for="(keyword, index) in hotKeywords" :key="index" :link="keyword.link" :title="keyword.title" class="keyword"> ＃{{ keyword.title }} </RouterLinkUsage>
        </div>
      </div>
    </div>
  </div>
  <div v-if="hotKeywords.length > 0" class="tw-pb-4"></div>
</template>

<script setup>
import { ref, computed, toRefs, watch, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useHomeAd2024Store } from '@/stores/home-ad-2024';
import RouterLinkUsage from '@/components/utility/router-link-usage.vue';
import { loadingStatusType } from '@/constant/loading-status-enum';
import { adjustBoxWidth } from '@/helper/calculation/calculate-container-width';

const props = defineProps({
  title: { type: String, default: '' },
  limit: { type: Number, default: 20 },
});
const { limit } = toRefs(props);

const homeAdStore = useHomeAd2024Store();
const { getHotKeywords } = storeToRefs(homeAdStore);

const hotKeywords = computed(() => (getHotKeywords.value?.items || []).slice(0, limit.value));

const containerRef = ref(null);
const rowCount = ref(0);
const containerWidth = ref('auto');
const status = ref(loadingStatusType.wait);

/** @const {number} firstNumber 起始值（左右padding寬度加總） */
const firstNumber = 64;
/** @const {number} keywordPadding 關鍵字左右間距 */
const keywordPadding = 10;
/** @const {number} maxRowWidth 區塊寬度 */
const maxRowWidth = 1160;

onMounted(async () => {
  await new Promise((resolve) => setTimeout(resolve, 200));
  // watch(containerWidth, (newVal, oldVal) => console.log({ newVal, oldVal }), { immediate: true });
  watch(
    hotKeywords,
    async () => {
      status.value = loadingStatusType.wait;
      await new Promise((resolve) => setTimeout(resolve, 100));
      await adjustBoxWidth(containerRef, '.keyword', status, rowCount, containerWidth, maxRowWidth, firstNumber, keywordPadding);
    },
    { immediate: true, deep: true },
  );
});
</script>

<style scoped>
.hot-keywords-box {
  @apply tw-h-[120px] tw-flex tw-w-full tw-overflow-hidden;
}

.bank-box {
  @apply tw-w-[120px] tw-h-[120px] tw-flex;
  img {
    @apply tw-w-[120px] tw-h-[120px] tw-flex tw-object-contain;
  }
}

.keywords-box {
  @apply tw-w-auto tw-h-[120px] tw-pl-8 tw-py-4;
  @apply tw-grid tw-content-center tw-overflow-x-auto tw-overflow-y-hidden;
}

.keywords-container {
  @apply tw-min-w-[1000px] tw-min-h-[120px] tw-w-fit;
  @apply tw-flex tw-flex-wrap tw-items-start tw-justify-start tw-content-center;
}

.keyword {
  @apply tw-h-[36px] tw-px-[18px] tw-py-[8px] tw-mx-[5px] tw-my-[6px] tw-rounded-3xl tw-text-[16px] tw-leading-[20px] tw-font-medium;
  @apply tw-flex tw-justify-center tw-items-center;

  color: theme('colors.camel-500');
  background-color: theme('colors.white');
}

.scroll-box {
  @apply tw-h-[120px] tw-overflow-hidden tw-relative;
  width: calc(100% - 120px);
}
.keyword-shadow {
  @apply tw-w-[1px] tw-h-[70%] tw-m-auto tw-absolute tw-inset-y-0 tw-left-[-1px] tw-hidden;
  content: '';
  box-shadow: -15px 0px 12px 9px theme('colors.black');
}

.scroll {
  .keyword-shadow {
    @apply tw-block;
  }
}
</style>
