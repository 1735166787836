import { computed } from 'vue';
import AbTestService from '@/services/utility/ab-test-service';
import { abConfigArguments } from '@/constant/ab-test/ab-test';

export function useAbTest(type, cookieName, ...params) {
  const abService = new AbTestService(type, cookieName);

  const exp = computed(() => {
    return abService.getExperience(...abConfigArguments.search, ...params);
  });
  return {
    exp,
  };
}
